import React, { useState } from "react";
import PropTypes from "prop-types";
import { Divider, IconButton } from "@mui/material";
import { X } from "lucide-react";
import MDBox from "components/MDBox";

const ExistData = ({ existResult, close }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    close();
  };

  return (
    isVisible && (
      <MDBox
        pl={3}
        pt={3}
        style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Already Exist Results</span>
          <IconButton onClick={handleClose}>
            <X color="red" />
          </IconButton>
        </div>
        {existResult && existResult.length > 0 ? (
          existResult.map((item) => {
            const { existingResult } = item;
            return (
              <div key={existingResult.result_id}>
                <p className="text-[14px]">
                  {existingResult.student.first_name} {existingResult.student.middle_name}{" "}
                  {existingResult.student.last_name}: {existingResult.exam.exam_name}{" "}
                  {existingResult.mark}
                </p>
                <Divider />
              </div>
            );
          })
        ) : (
          <p>No Results</p>
        )}
      </MDBox>
    )
  );
};

ExistData.propTypes = {
  existResult: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};

export default ExistData;
