export const validateResultUploadFile = ({ examType, students, examHeader, data }) => {
  const errors = [];
  const requiredHeaders = ["student_id", "student_name", ...examHeader];
  const sheetHeaders = data[0];

  console.log("Expected headers:", requiredHeaders);
  console.log("Sheet headers:", sheetHeaders);

  // Check for missing headers
  requiredHeaders.forEach((header, index) => {
    if (sheetHeaders[index] !== header) {
      errors.push(
        `Invalid Excel format: Expected "${header}" but got "${sheetHeaders[index]}" in column ${
          index + 1
        }.`
      );
    }
  });

  // If headers validation fails, exit early
  if (errors.length > 0) {
    throw new Error(errors.join("\n"));
  }

  // Extract and normalize student IDs
  const studentIds = students.map((student) => String(student.student_id).trim());
  console.log("Valid student IDs:", studentIds);

  // Map exam types to their maximum results for easy access
  const examTypeLimits = examType.reduce((acc, exam) => {
    acc[exam.exam_name] = exam.max_result;
    return acc;
  }, {});

  // Validate if all student IDs in the file exist in the database
  data.slice(1).forEach((row, index) => {
    const studentId = String(row[0]).trim(); // Normalize ID by converting to string and trimming

    if (!studentIds.includes(studentId)) {
      errors.push(`Row ${index + 2}: Student ID ${studentId} is not valid.`);
    }

    // Validate each exam mark
    examHeader.forEach((examName, examIndex) => {
      const maxResult = examTypeLimits[examName];
      const studentMark = row[examIndex + 2]; // Offset by 2 because student_id and student_name are the first columns

      if (typeof studentMark === "number") {
        if (studentMark < 0) {
          errors.push(
            `Row ${
              index + 2
            }: Mark for exam "${examName}" cannot be negative. Please provide a valid score.`
          );
        } else if (studentMark > maxResult) {
          errors.push(
            `Row ${
              index + 2
            }: Mark for exam "${examName}" (${studentMark}) exceeds maximum allowed (${maxResult}).`
          );
        }
      }
    });
  });

  if (errors.length > 0) {
    throw new Error(errors.join("\n"));
  }
};
