import MDBox from "components/MDBox";
import { useState } from "react";
import MDButton from "components/MDButton";
import { LoadinSpiner } from "components/LoadingSpinner";
import Lottie from "lottie-react";
import checkMark from "assets/lottie/Animation - 1709580077501.json";
import SelectFile from "assets/lottie/Animation - 1709585629758.json";
import CheckingFile from "assets/lottie/Animation - 1709585724092.json";
import { axiosInstanceAuth } from "config/axios-config";
import "../../index.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { snackbar } from "components/awesome_snackbar/snackbar";
import PropTypes from "prop-types";
import { validateExcelFile } from "./validateExcel";
import { Typography } from "@mui/material";

export const FileUpload = ({ isStaff, datas, roles }) => {
  const uploadType = isStaff ? "staff" : "parent";
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState();
  const [validating, setValidating] = useState(false);
  const [backedDatas, setBackData] = useState([]);
  const staffUploadHeaders = [
    "first_name",
    "last_name",
    "phone_number",
    "email",
    "subject",
    "educational_level",
    "grade_level",
    "grade_section",
    "role",
  ];

  const studentUploadHeaders = [
    "first_namep",
    "last_namep",
    "phone_numberp",
    "emailp",
    "student_first_name",
    "student_middle_name",
    "student_last_name",
    "student_email",
    "student_phone_number",
    "grade_level",
    "grade_section",
    "student_birth_date",
    "student_gender",
    "student_is_premium",
  ];
  // Function to generate the Excel template for download
  const generateTemplate = () => {
    const headers = isStaff ? staffUploadHeaders : studentUploadHeaders;
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, uploadType === "staff" ? "Staff Data" : "Student Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `${uploadType}_template.xlsx`);
  };
  const handleUploadClick = async () => {
    if (!file) {
      snackbar("error", "Please upload a file first");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });

      try {
        validateExcelFile({
          data: worksheet,
          isStaff: isStaff,
          datas: datas,
          studentUploadHeaders: studentUploadHeaders,
          staffUploadHeaders: staffUploadHeaders,
        });
      } catch (error) {
        snackbar("error", error.message || "Invalid Excel file");
        return;
      }

      if (worksheet.length <= 1) {
        snackbar("error", "The uploaded file is empty. Please provide a valid file.");
        return;
      }

      let axiosAuth = axiosInstanceAuth().axiosUploadInstance;
      try {
        setValidating(true);
        setValidating(false);
        const formData = new FormData();
        formData.append("file", file);

        setIsUploading(true);
        const response = await axiosAuth.post(`/${uploadType}/upload`, formData);
        console.log("response from ", JSON.stringify(response, null, 2));

        if (isStaff ? response.success : response.data.success) {
          setUploadSuccess(true);
          setBackData(response.data.data);
          snackbar("success", isStaff ? response.message : response.data.message);
        } else {
          console.log("response error", JSON.stringify(response, null, 2));
          setUploadSuccess(false);
          snackbar("error", isStaff ? response.message : response.data.message);
        }
      } catch (error) {
        const errorMessage = "An error occurred during upload, please try again later";
        snackbar("error", errorMessage);
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  console.log("after put data over backe data ", backedDatas);
  return (
    <MDBox pt={4} flex={1} mx={3} my={10}>
      <div className="border-dashed border border-gray-400 rounded-md ">
        <label htmlFor="file-upload" className="cursor-pointer inset-0">
          <input
            type="file"
            id="file-upload"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx,.xls,.csv"
            tabIndex={-1}
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              position: "absolute",
              zIndex: -1,
            }}
          />
          {file !== null ? (
            <Lottie animationData={CheckingFile} loop={false} style={{ height: 100 }} />
          ) : (
            <Lottie animationData={SelectFile} loop={false} style={{ height: 100 }} />
          )}
          <p className="mt-3 text-gray-300 max-w-xs mx-auto">
            {isUploading ? "Uploading..." : "Click or Drag and Drop to Upload"}
          </p>
        </label>
      </div>

      {isUploading ? (
        <LoadinSpiner />
      ) : uploadSuccess ? (
        <MDBox>
          <Lottie animationData={checkMark} loop={false} style={{ height: 100 }} />
          {backedDatas && backedDatas.length > 0 && (
            <MDBox style={{ maxHeight: "400px", overflowY: "auto" }}>
              {Object.entries(
                backedDatas.reduce((acc, data) => {
                  const reason = data.reason;
                  if (!acc[reason]) acc[reason] = [];
                  acc[reason].push(data);
                  return acc;
                }, {})
              ).map(([reason, students], index) => (
                <MDBox key={index} p={2} mb={2} border={1} borderRadius={2} borderColor="grey.300">
                  <Typography variant="h6">{reason}</Typography>
                  {students.map((data, idx) => (
                    <MDBox key={idx} pl={2} pt={1}>
                      <p>
                        {data.row.student_first_name} {data.row.student_middle_name}{" "}
                        {data.row.student_last_name}
                      </p>
                    </MDBox>
                  ))}
                </MDBox>
              ))}
            </MDBox>
          )}
        </MDBox>
      ) : (
        <MDBox p={2} display="flex" gap={2}>
          {/* Download Template Button */}
          <MDButton
            onClick={generateTemplate}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="primary"
          >
            Download Template
          </MDButton>

          {/* Upload Button */}
          <MDButton
            onClick={handleUploadClick}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="light"
          >
            Upload
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
};

FileUpload.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  datas: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
};
