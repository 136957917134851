import { axiosInstanceAuth } from "config/axios-config";

export const getResults = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;

  try {
    const response = await axiosAuth.get("/result/teacher");
    // console.log("object returned", JSON.stringify(response, null, 2));
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "Failed to fetch results.",
        data: null,
      };
    }
  } catch (error) {
    console.error("Error fetching results:", error);
    return {
      success: false,
      message: error.message || "An error occurred while fetching results.",
      data: null,
    };
  }
};

export const getAllClassResults = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get("/result");
    // // console.log("object returned", JSON.stringify(response, null, 2));
    if (response.data.success) {
      return {
        success: response.data.success,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: response.data.success,
        message: response.data.message,
        data: null,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
      data: null,
    };
  }
};
