import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadinSpiner } from "../components/LoadingSpinner";
import { isAuthenticated } from "context/isAuthenticated.js";
import Backdrop from "@mui/material/Backdrop";

const PrivateRoute = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      setIsLoading(true);
      const authenticated = await isAuthenticated();
      setIsAuthenticatedState(authenticated);
      setIsLoading(false);
    };

    checkAuthentication();
  }, []);

  // Show loading spinner while checking authentication
  if (isLoading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <LoadinSpiner />
      </Backdrop>
    );
  }

  // If not authenticated, redirect to the login page
  return isAuthenticatedState ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
