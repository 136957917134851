import { axiosInstanceAuth } from "config/axios-config";

export const getSchedules = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get("/programGenerator").catch((error) => {
      // console.log(error);
    });
    // console.log("response from getschedules", response);
    return response;
  } catch (error) {
    // console.log(error.data);
  }
};
