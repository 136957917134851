import { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { LoadinSpiner } from "components/LoadingSpinner";
import Lottie from "lottie-react";
import checkMark from "assets/lottie/Animation - 1709580077501.json";
import SelectFile from "assets/lottie/Animation - 1709585629758.json";
import CheckingFile from "assets/lottie/Animation - 1709585724092.json";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { getExamType } from "utils/requests/exam/examrequest";
import { getStudentsByClass } from "utils/requests/students/studentsget";
import { getResults } from "utils/requests/result/getResult";
import { validateResultUploadFile } from "./resultExcelUpload";
import { axiosInstanceAuth } from "config/axios-config";
import CryptoJS from "crypto-js";
import ExistData from "./existData";
import PropTypes from "prop-types";

const ResultUpload = ({ onDataReceived }) => {
  const [examHeaders, setExamHeaders] = useState([]);
  const [students, setStudents] = useState([]);
  const [resultsData, setResultsData] = useState([]);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [examType, setExamType] = useState([]);

  // Fetch exam headers and students
  const fetchExamHeadersAndStudents = async () => {
    try {
      const examTypesResponse = await getExamType();
      setExamType(examTypesResponse.data);
      const examTypes = examTypesResponse.data.map((exam) => exam.exam_name);

      const studentsResponse = await getStudentsByClass();
      const resultsResponse = await getResults();
      // console.log("result response", JSON.stringify(resultsResponse, null, 2));
      setExamHeaders(examTypes);
      setStudents(studentsResponse.data);
      setResultsData(resultsResponse.data);
    } catch (error) {
      snackbar("error", "Failed to fetch exam types or students");
    }
  };

  useEffect(() => {
    fetchExamHeadersAndStudents();
  }, []);

  // Handle file change
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && /\.(xlsx|xls|csv)$/i.test(selectedFile.name)) {
      setFile(selectedFile);
    } else {
      snackbar("error", "Please select a valid Excel or CSV file");
    }
  };

  // Handle upload click
  const handleUploadClick = async () => {
    if (!file) {
      snackbar("error", "Please select a file before uploading");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });

      try {
        validateResultUploadFile({
          examType: examType,
          students: students,
          examHeader: examHeaders,
          data: worksheet,
        });
      } catch (error) {
        snackbar("error", error.message || "Invalid Excel file");
        return;
      }

      if (worksheet.length <= 1) {
        snackbar("error", "The uploaded file is empty. Please provide a valid file.");
        return;
      }

      const axiosAuth = axiosInstanceAuth().axiosUploadInstance;
      try {
        setIsUploading(true);

        // Prepare the data to be sent to the backend
        const dataToSend = {
          studentResults: worksheet,
          // Add any other relevant data if needed
        };

        // Encrypt the data
        const secretKey = process.env.REACT_APP_SECRET_KEY; // Ensure this is defined in your .env file
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(dataToSend),
          secretKey
        ).toString();

        // Send the encrypted data
        const response = await axiosAuth.post(`/result/upload`, { data: encryptedData });
        console.log("response:of result " + JSON.stringify(response, null, 2));
        if (response.data.success) {
          setUploadSuccess(true);
          snackbar("success", response.data.message);
          onDataReceived(response.data.data);
        } else {
          snackbar("error", response.data.message);
          return;
        }
      } catch (error) {
        snackbar("error", "An error occurred during upload");
      } finally {
        setIsUploading(false);
      }
    };

    reader.readAsBinaryString(file);
  };
  // Generate template for downloading
  const generateTemplate = () => {
    if (!examHeaders || examHeaders.length === 0 || !students || students.length === 0) {
      snackbar("error", "Exam headers or students not available");
      return;
    }

    // Define headers for the Excel sheet
    const headers = ["student_id", "student_name", ...examHeaders];

    // Populate data for each student
    const data = students.map((student) => {
      const row = [
        student.student_id,
        `${student.first_name} ${student.middle_name} ${student.last_name}`,
      ];

      // Map each exam type to the correct mark
      examHeaders.forEach((examName) => {
        const studentExamResult = resultsData.find(
          (result) =>
            result.student_id === student.student_id &&
            result.exam.exam_name.toLowerCase() === examName.toLowerCase()
        );
        row.push(studentExamResult ? studentExamResult.mark : null);
      });

      return row;
    });

    // Convert data to Excel sheet and initiate download
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exam Results");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, "exam_results_template.xlsx");
  };

  // console.log("result of returned", returnData);
  return (
    <MDBox pt={4} flex={1} mx={3} my={10}>
      <div className="border-dashed border border-gray-400 rounded-md ">
        <label htmlFor="file-upload" className="cursor-pointer inset-0">
          <input
            type="file"
            id="file-upload"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx,.xls,.csv"
            tabIndex={-1}
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              position: "absolute",
              zIndex: -1,
            }}
          />
          {file !== null ? (
            <Lottie animationData={CheckingFile} loop={false} style={{ height: 100 }} />
          ) : (
            <Lottie animationData={SelectFile} loop={false} style={{ height: 100 }} />
          )}
          <p className="mt-3 text-gray-300 max-w-xs mx-auto">
            {isUploading ? "Uploading..." : "Click or Drag and Drop to Upload"}
          </p>
        </label>
      </div>

      {isUploading ? (
        <LoadinSpiner />
      ) : uploadSuccess ? (
        <Lottie animationData={checkMark} loop={false} style={{ height: 100 }} />
      ) : (
        <MDBox p={2} display="flex" gap={2}>
          <MDButton
            onClick={generateTemplate}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="primary"
          >
            Download Template
          </MDButton>

          <MDButton
            onClick={handleUploadClick}
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            fullWidth
            color="light"
          >
            Upload
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
};
ResultUpload.propTypes = {
  onDataReceived: PropTypes.func.isRequired,
};

export default ResultUpload;
