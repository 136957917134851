const AvatarColor = (name) => {
  const colors = [
    "red",
    "blue",
    "green",
    "orange",
    "purple",
    "cyan",
    "teal",
    "pink",
    "lime",
    "indigo",
  ];
  const index = name.charCodeAt(0) % colors.length;
  return colors[index];
};
export default AvatarColor;
