import { axiosInstanceAuth } from "config/axios-config";
import { historymessageSetter } from "context/historymessages";
export const getMessage = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get("/message/sender");
    if (response) {
      historymessageSetter(response.data.data);
    }
  } catch (error) {
    console.log("error");
  }
};
