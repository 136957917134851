import React, { useState } from "react";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material"; // Import Select and MenuItem
import { snackbar } from "components/awesome_snackbar/snackbar";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { createSchoolPlan } from "utils/requests/schoolPlan/createSchool";
const CreateSchoolPlan = ({ onPlanAdded }) => {
  const [semester, setSemester] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState({});

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Custom validation function
  const validate = () => {
    const newErrors = {};
    if (!semester) {
      newErrors.semester = "Semester is required.";
    }
    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }
    if (!endDate) {
      newErrors.endDate = "End date is required.";
    }
    if (startDate && endDate && startDate >= endDate) {
      newErrors.date = "Start date must be before the end date.";
    }
    if (new Date(startDate) < new Date()) {
      newErrors.startDate = "Start date must be in the future.";
    }
    if (new Date(endDate) < new Date()) {
      newErrors.endDate = "End date must be in the future.";
    }
    return newErrors;
  };

  // Submit form handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    try {
      const response = await createSchoolPlan({ semester, startDate, endDate });

      if (response.success) {
        snackbar("success", response.message);
        onPlanAdded(); // Call the parent method to re-fetch data and close the form
      } else {
        snackbar("error", response.message);
      }
    } catch (error) {
      snackbar("error", "An error occurred while creating the school plan.");
    }
  };

  return (
    <MDBox p={3}>
      <MDTypography variant="h6" mb={2}>
        Create School Plan
      </MDTypography>
      {/* Flex container for input fields */}
      <MDBox display="flex" flexDirection={isSmallScreen ? "column" : "row"} gap={2}>
        <FormControl fullWidth margin="normal" error={!!errors.semester}>
          <InputLabel id="semester-label">Semester</InputLabel>
          <Select
            labelId="semester-label"
            sx={{ height: "44px" }}
            value={semester}
            onChange={(e) => setSemester(e.target.value)}
            required
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </Select>
          {errors.semester && (
            <MDTypography variant="caption" color="error">
              {errors.semester}
            </MDTypography>
          )}
        </FormControl>

        <TextField
          fullWidth
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.startDate}
          helperText={errors.startDate}
        />
        <TextField
          fullWidth
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.endDate}
          helperText={errors.endDate}
        />
      </MDBox>
      {errors.date && (
        <MDTypography variant="caption" color="error">
          {errors.date}
        </MDTypography>
      )}
      <MDBox display="flex" justifyContent="flex-center" px={2} mt={2}>
        <MDButton variant="contained" color="info" onClick={handleSubmit}>
          Submit
        </MDButton>
      </MDBox>
    </MDBox>
  );
};
CreateSchoolPlan.propTypes = {
  onPlanAdded: PropTypes.func.isRequired, // Validate that onPlanAdded is a required function
};
export default CreateSchoolPlan;
