export const validatePhone = (phone) => {
  const phonePattern = /^(?:\+2519\d{8}|\+2517\d{8}|2519\d{8}|2517\d{8}|\d{10})$/;

  if (!phone) {
    return "Phone number is required";
  }

  if (!phonePattern.test(phone)) {
    return "Phone number must be 10 digits starting with 09 or 07, or 14 digits starting with +2519 or +2517";
  }

  return null;
};
