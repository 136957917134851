import { axiosInstanceAuth } from "config/axios-config";
import { profile } from "context/profile";

export const getSchoolPlan = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;

  try {
    const response = await axiosAuth.get("/schoolPlan");
    // // console.log("object returned", JSON.stringify(response, null, 2));
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message,
        data: null,
      };
    }
  } catch (error) {
    // console.error("Error fetching results:", error);
    return {
      success: false,
      message: error.response.data.message || "An error occurred while fetching school plan.",
      data: null,
    };
  }
};
export const getSchool = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get(`/school/${profile.school_id}`);
    // console.log("object returned", JSON.stringify(response, null, 2));
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "Failed to fetch school plan.",
        data: null,
      };
    }
  } catch (error) {
    // console.error("Error fetching results:", error);
    return {
      success: false,
      message: error.message || "An error occurred while fetching school plan.",
      data: null,
    };
  }
};
