import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import Snackbar from "awesome-snackbar";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BroadcastHistory from "layouts/billing/brodcasthistory";
function MessageSend(props) {
  let { handler, success } = props;
  const isEnglishCharacter = (char) => /^[a-zA-Z ' , .\s]+$/.test(char);

  const [alerts, setAlerts] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [messageData, setMessageData] = useState({
    message: "",
    selectedIds: [],
  });
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    setMessageData({ message: "", selectedIds: [] });
  }, [success]);

  const handleChange = (messageData) => (event) => {
    setValues({ ...values, [messageData]: event.target.value });
  };

  const getCharacterLimit = (message) => {
    for (let char of message) {
      if (!isEnglishCharacter(char)) {
        return 70;
      }
    }
    return 160;
  };
  const handleOnChange = (messageData) => (event) => {
    const CHARACTER_LIMIT = getCharacterLimit(value);

    if (value.length <= CHARACTER_LIMIT) {
      setMessageData({ ...messageData, message: value });
    }
  };
  const showAlert = () => {
    if (messageData.message.trim() === "") {
      setAlerts(true);
    } else {
      setAlerts(false);
    }
  };

  const handleHistoryClick = () => {
    setViewHistory(true);
  };

  // Function to handle going back to the original page
  const handleBackClick = () => {
    setViewHistory(false);
  };

  return (
    <Card sx={{ height: 410 }}>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6" fontWeight="medium">
            {viewHistory ? "Broadcast History" : "Message Broadcasting"}
          </MDTypography>
          <MDBox display="flex" alignItems="center">
            <IconButton onClick={viewHistory ? handleBackClick : handleHistoryClick}>
              {viewHistory ? <ArrowBackIcon /> : <HistoryIcon />}
            </IconButton>
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          </MDBox>
        </MDBox>
        <>
          <BroadcastHistory isrefetch={refetch} />
          <div
            className="relative"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              fullWidth
              inputProps={{
                maxLength: getCharacterLimit(messageData.message),
              }}
              label="Enter your message"
              id="fullWidth"
              multiline
              minRows={2}
              maxRows={3}
              value={messageData.message}
              onChange={(e) => {
                setMessageData({ ...messageData, message: e.target.value });
                handleChange("messageData");
                handleOnChange();
                setAlerts(false);
              }}
              disableUnderline
              helperText={`${messageData.message.length}/${getCharacterLimit(
                messageData.message
              )} characters`}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "left",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  paddingRight: "100px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                  overflowY: "auto",
                },
                "& fieldset": {
                  borderRadius: "10px",
                },
              }}
            />

            <button
              onClick={() => {
                handler(messageData.message);
                setRefetch(!refetch);
                setMessageData({ message: "", selectedIds: [] });
              }}
              type="submit"
              className="absolute right-0 -bottom-40 rounded-full bg-blue-500 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              style={{
                width: "90px", // Set fixed width
                height: "35px", // Set fixed height
                borderRadius: "10px", // Fully rounded corners
                padding: "0 20px",
                right: "10px", // Positioned to the right edge
                bottom: "32px", // Positioned to the bottom of the text field
              }}
            >
              send
            </button>
          </div>
        </>
        {/* )} */}
      </CardContent>
      {/* <CardActions sx={{ justifyContent: "center", marginTop: 5 }}>
        <MDTypography variant="h6" fontWeight="medium"></MDTypography>
      </CardActions> */}
    </Card>
  );
}
MessageSend.propTypes = {
  handler: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
};
export default MessageSend;
