import { IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { getClassBySchool } from "utils/requests/class/getClass";
import { createSchedule } from "utils/requests/schedule/createSchedule";
import { getGradeLevelSubject } from "utils/requests/subjects/subjectRequests";
import Snackbar from "awesome-snackbar";
import { X } from "lucide-react";
const ScheduleForm = () => {
  const [formData, setFormData] = useState({
    grade_level: "",
    one_subject_period_duration: "",
    sections: "",
    subjects_with_class_in_a_week: {},
    start_time: "",
    end_time: "",
    breaks: [{ start_time: "", end_time: "" }],
  });

  const [errors, setErrors] = useState({});
  const [classData, setClassData] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [gradeLevelSubjects, setGradeLevelSubject] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [showSubjectInputs, setShowSubjectInputs] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [generatedSchedule, setGeneratedSchedule] = useState(null);

  useEffect(() => {
    const fetchClassData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
      setErrors(result.errors || {});
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchSubjectsByGradeLevel = async () => {
      const result = await getGradeLevelSubject();
      if (result.success) {
        setGradeLevelSubject(result.data);
      }
      setErrors(result.errors || {});
    };
    fetchSubjectsByGradeLevel();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Handle changes in the breaks
    if (name === "sections") {
      setFormData((prevData) => ({
        ...prevData,
        sections: value, // This will now be the section letter
      }));
    } else if (name.startsWith("break_")) {
      const index = parseInt(name.split("_")[1], 10); // Break index
      const type = name.split("_")[2]; // "start" or "end" part of the break

      setFormData((prevData) => {
        const updatedBreaks = [...prevData.breaks]; // Create a new copy of the breaks array

        if (type === "start") {
          updatedBreaks[index].start_time = value; // Update start_time
        } else if (type === "end") {
          updatedBreaks[index].end_time = value; // Update end_time
        }

        return {
          ...prevData,
          breaks: updatedBreaks, // Set the updated breaks array back into formData
        };
      });
    } else if (name.startsWith("subject_")) {
      const subject = name.split("_")[1];
      // Convert the value to a number
      const numericValue = parseInt(value, 10);

      // Ensure the numericValue is a valid number
      if (!isNaN(numericValue)) {
        setFormData((prevData) => ({
          ...prevData,
          subjects_with_class_in_a_week: {
            ...prevData.subjects_with_class_in_a_week,
            [subject]: numericValue, // Assign the number value here
          },
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveBreak = (index) => {
    setFormData((prevData) => {
      const updatedBreaks = prevData.breaks.filter((_, i) => i !== index);
      return {
        ...prevData,
        breaks: updatedBreaks,
      };
    });
  };

  const handleGradeLevelChange = async (e) => {
    const selectedGrade = Number(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      grade_level: selectedGrade,
      sections: "",
      subjects_with_class_in_a_week: {},
    }));

    const sectionsForGrade = classData
      .filter((cls) => cls.grade_level === selectedGrade)
      .map((cls) => cls.section);
    setFilteredSections(sectionsForGrade);

    const subjectForGrade = gradeLevelSubjects
      .filter((gradeLevelSubject) => gradeLevelSubject.grade_level === selectedGrade)
      .map((gradeLevelSubject) => gradeLevelSubject.subject.name);

    setSubjects(subjectForGrade);
  };

  const handleAddBreak = () => {
    setFormData((prevData) => ({
      ...prevData,
      breaks: [...prevData.breaks, { start_time: "", end_time: "" }],
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if sections are empty or not "all"
    if (formData.sections !== "all") {
      new Snackbar("Sections must be set to 'All'.", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    // Check if start and end times are provided
    if (formData.end_time === "" || formData.start_time === "") {
      new Snackbar("Please enter start and end time of school", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    // Validate that start time is before end time
    const startTime = new Date(`1970-01-01T${formData.start_time}:00`);
    const endTime = new Date(`1970-01-01T${formData.end_time}:00`);

    if (startTime >= endTime) {
      new Snackbar("Start time must be before end time.", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    // Check if at least one subject is specified
    const subjectCount = Object.values(formData.subjects_with_class_in_a_week).reduce(
      (acc, val) => acc + (val > 0 ? 1 : 0),
      0
    );
    if (subjectCount === 0) {
      new Snackbar("At least one subject must be specified.", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    // Check if duration of one subject period is provided
    if (!formData.one_subject_period_duration || formData.one_subject_period_duration <= 0) {
      new Snackbar("Duration of one subject period must be a positive number.", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    // Validate breaks
    const invalidBreaks = formData.breaks.some((breakItem) => {
      const breakStart = new Date(`1970-01-01T${breakItem.start_time}:00`);
      const breakEnd = new Date(`1970-01-01T${breakItem.end_time}:00`);

      // Check if break times are filled
      if (!breakItem.start_time || !breakItem.end_time) {
        return true; // Incomplete break time
      }

      // Check if break is within start and end times
      if (breakStart < startTime || breakEnd > endTime || breakStart >= breakEnd) {
        return true; // Invalid break time
      }

      return false; // Break is valid
    });

    if (invalidBreaks) {
      new Snackbar(
        "Please fill in all break start and end times correctly. Breaks must be within start and end times.",
        {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "red"],
              ["color", "white"],
            ],
          },
        }
      );
      return;
    }

    // Prepare data for submission
    const dataToSubmit = {
      class_grade_level: Number.parseInt(formData.grade_level),
      one_subject_period_duration: Number.parseInt(formData.one_subject_period_duration),
      sections: Number.parseInt(
        formData.sections === "all" ? filteredSections.length : filteredSections.length
      ),
      subjects_with_class_in_aweek: formData.subjects_with_class_in_a_week,
      start_time: formData.start_time,
      end_time: formData.end_time,
      breaks: formData.breaks.map(({ start_time, end_time }) => ({ start_time, end_time })),
    };

    try {
      const response = await createSchedule(dataToSubmit);
      if (response.success) {
        new Snackbar(`${response.data.message}`, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "green"],
              ["color", "white"],
            ],
          },
        });
        setGeneratedSchedule(response.data.schedule);
      } else {
        new Snackbar(`${response.error}`, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "red"],
              ["color", "white"],
            ],
          },
        });
      }
    } catch (error) {
      new Snackbar("Submission failed: ", {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };

  const toggleSubjectInputs = () => {
    setShowSubjectInputs((prev) => !prev);
  };

  return (
    <MDBox mx={4} mt={3} mb={4}>
      <MDBox>
        <>
          <MDBox mb={2}>
            <select
              id="grade_level"
              name="grade_level"
              value={formData.grade_level}
              onChange={handleGradeLevelChange}
              style={{
                width: "45%",
                fontSize: "14px",
                padding: "10px",
                borderRadius: "5px",
                color: "grey",
                border: "1px solid lightgrey",
                marginTop: 10,
              }}
            >
              <option value="">Grade *</option>
              {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
            {errors.grade_level && (
              <div style={{ color: "red", marginTop: 5 }}>{errors.grade_level}</div>
            )}

            <select
              id="grade_section"
              name="sections"
              value={formData.sections}
              onChange={handleChange}
              style={{
                width: "45%",
                fontSize: "14px",
                padding: "10px",
                borderRadius: "5px",
                color: "grey",
                border: "1px solid lightgrey",
                marginTop: 20,
                marginLeft: "5%",
              }}
              disabled={filteredSections.length === 0}
            >
              <option value="">Section *</option>
              <option value="all">All *</option>
              {filteredSections.map((section, index) => (
                <option key={index} value={section}>
                  {section}
                </option>
              ))}
            </select>
            {errors.sections && <div style={{ color: "red", marginTop: 5 }}>{errors.sections}</div>}
          </MDBox>

          <MDBox mb={2}>
            <MDButton
              variant="contained"
              color="info"
              style={{ width: "45%", marginTop: "10px" }}
              onClick={toggleSubjectInputs}
              disabled={!formData.grade_level}
            >
              {showSubjectInputs ? "Hide Subject Inputs" : "Add Subjects"}
            </MDButton>

            {showSubjectInputs && (
              <>
                {subjects.map((subject, index) => (
                  <MDBox mb={2} key={index}>
                    <MDInput
                      id={`subject_${subject}`}
                      label={`Number of ${subject} Periods`}
                      name={`subject_${subject}`}
                      type="number"
                      min={1}
                      max={5}
                      value={formData.subjects_with_class_in_a_week[subject] || ""}
                      onChange={handleChange}
                      style={{ width: "45%", marginTop: "10px" }}
                    />
                  </MDBox>
                ))}
                {errors.subjects_with_class_in_a_week && (
                  <div style={{ color: "red", marginTop: 5 }}>
                    {errors.subjects_with_class_in_a_week}
                  </div>
                )}
              </>
            )}
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              id="one_subject_period_duration"
              name="one_subject_period_duration"
              label="Duration of One Subject Period (minutes)"
              type="number"
              value={formData.one_subject_period_duration}
              onChange={handleChange}
              style={{ width: "45%", marginTop: "10px" }}
            />
            {errors.one_subject_period_duration && (
              <div style={{ color: "red", marginTop: "5%" }}>
                {errors.one_subject_period_duration}
              </div>
            )}
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              id="start_time"
              label="Start Time"
              type="time"
              name="start_time"
              value={formData.start_time}
              onChange={handleChange}
              style={{ width: "45%", marginTop: "10px" }}
            />
            <MDInput
              id="end_time"
              label="End Time"
              type="time"
              name="end_time"
              value={formData.end_time}
              onChange={handleChange}
              style={{ width: "45%", marginTop: "10px", marginLeft: "5%" }}
            />
            {errors.start_time && (
              <div style={{ color: "red", marginTop: 5 }}>{errors.start_time}</div>
            )}
            {errors.end_time && <div style={{ color: "red", marginTop: 5 }}>{errors.end_time}</div>}
          </MDBox>

          <MDBox mb={2}>
            <Typography variant="h6">Breaks</Typography>
            {formData.breaks.map((breakItem, index) => (
              <MDBox mb={2} key={index}>
                <MDInput
                  id={`break_${index}_start_time`}
                  name={`break_${index}_start_time`}
                  label={`Break ${index + 1} Start Time`}
                  type="time"
                  value={breakItem.start_time}
                  onChange={handleChange}
                  style={{ width: "45%" }}
                />
                <MDInput
                  id={`break_${index}_end_time`}
                  name={`break_${index}_end_time`}
                  label={`Break ${index + 1} End Time`}
                  type="time"
                  value={breakItem.end_time}
                  onChange={handleChange}
                  style={{ width: "45%", marginLeft: "5%" }}
                />
                {index > 0 && (
                  <IconButton color="error" onClick={() => handleRemoveBreak(index)}>
                    <X />
                  </IconButton>
                )}
              </MDBox>
            ))}
            <MDButton
              variant="contained"
              color="info"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "5%",
                float: "right",
              }}
              onClick={handleAddBreak}
            >
              Add Break
            </MDButton>
          </MDBox>
          <MDButton
            variant="contained"
            color="success"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            Submit Schedule
          </MDButton>
        </>
      </MDBox>
    </MDBox>
  );
};

export default ScheduleForm;
