import React from "react";
import PropTypes from "prop-types";
import AvatarColor from "components/ColorAvater/ColorAvater";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Avatar } from "@mui/material";
import { Check } from "@mui/icons-material";

// CustomHr component moved outside for reuse and clarity
export const CustomHr = ({ height, color }) => {
  const hrStyle = {
    height: `${height}px`,
    backgroundColor: color,
  };
  return <div className="custom-hr" style={hrStyle}></div>;
};

const AttendanceComp = (props) => {
  const { name, grade, selected, section, lastName } = props;
  const displayName = name[0].toUpperCase() + name.substring(1);

  return (
    <MDTypography>
      <MDBox display="flex" flexDirection="row" mt={1} ml={1}>
        <MDBox>
          <Avatar sx={{ bgcolor: selected ? "red" : AvatarColor(displayName) }}>
            {selected ? <Check /> : displayName.charAt(0)}
          </Avatar>
        </MDBox>
        &nbsp;
        <MDBox>
          <p style={{ fontSize: 14 }}>
            {displayName} {lastName}
          </p>
        </MDBox>
      </MDBox>
      <MDTypography
        mt={-2}
        ml={-12}
        fontWeight="light"
        color="secondary"
        sx={{ fontSize: "small", textAlign: "center" }}
      >
        Grade: {grade} {section}
      </MDTypography>

      <CustomHr height={1} color="#f0f2f5" />
    </MDTypography>
  );
};

// Updated PropTypes
AttendanceComp.propTypes = {
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  grade: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

// CustomHr PropTypes
CustomHr.propTypes = {
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default AttendanceComp;
