export const validateExcelFile = ({
  data,
  isStaff,
  datas,
  staffUploadHeaders,
  studentUploadHeaders,
}) => {
  const uploadType = isStaff ? "staff" : "parent";
  const { classDatas, subjects, roles } = datas;
  const headers = uploadType === "staff" ? staffUploadHeaders : studentUploadHeaders;
  const sheet = data[0];
  const headersFromSheet = sheet.slice(0, headers.length);

  // Check headers
  for (let i = 0; i < headers.length; i++) {
    if (headersFromSheet[i] !== headers[i]) {
      throw new Error(`Invalid Excel format, please download exact excel format and use it`);
    }
  }

  // Optional fields and indexes
  const optionalFields = [
    "student_email",
    "student_phone_number",
    "student_is_premium",
    "subject",
    "grade_level",
    "grade_section",
    "phone_numberp",
  ];
  const optionalIndexes = optionalFields.map((field) => headers.indexOf(field));
  const requiredIndexes = headers
    .map((header, index) => index)
    .filter((index) => !optionalIndexes.includes(index));

  for (let i = 1; i < data.length; i++) {
    const row = data[i];

    // Check for required fields
    for (const index of requiredIndexes) {
      if (row[index] === "" || row[index] === null || row[index] === undefined) {
        throw new Error(`Row ${i + 1}, Column "${headers[index]}" is required but missing.`);
      }
    }

    // Validate role (staff only)
    if (isStaff) {
      const roleIndex = headers.indexOf("role"); // Assuming 'role' is the header for role data
      const role = row[roleIndex];
      if (!roles.includes(role)) {
        throw new Error(
          `Row ${
            i + 1
          }: Invalid role "${role}". Valid roles are: teacher, encoder, admin, registrar.`
        );
      }
    }

    // Validate grade level and section if values are provided
    const gradeLevelIndex = headers.indexOf("grade_level");
    const sectionIndex = headers.indexOf("grade_section");
    const gradeLevel = row[gradeLevelIndex];
    const section = row[sectionIndex];

    if (gradeLevel || section) {
      const validClass = classDatas.some(
        (classData) =>
          classData.grade_level === gradeLevel &&
          classData.section.toLowerCase() === section.toLowerCase()
      );
      if (!validClass) {
        throw new Error(
          `Row ${i + 1}: Invalid class combination for grade ${gradeLevel} and section ${section}.`
        );
      }
    }

    // Validate subject only if provided
    const subjectIndex = headers.indexOf("subject");
    const subject = row[subjectIndex];
    if (subject) {
      const validSubject = subjects.some((sub) => sub.name.toLowerCase() === subject.toLowerCase());
      if (!validSubject) {
        throw new Error(`Row ${i + 1}: Invalid subject "${subject}"`);
      }
    }

    // Validate email format for staff and student
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const staffEmailIndex = headers.indexOf("email");
    const studentEmailIndex = headers.indexOf("student_email");

    if (staffEmailIndex !== -1 && row[staffEmailIndex] && !emailRegex.test(row[staffEmailIndex])) {
      throw new Error(`Row ${i + 1}: Invalid staff email format "${row[staffEmailIndex]}"`);
    }
    if (
      studentEmailIndex !== -1 &&
      row[studentEmailIndex] &&
      !emailRegex.test(row[studentEmailIndex])
    ) {
      throw new Error(`Row ${i + 1}: Invalid student email format "${row[studentEmailIndex]}"`);
    }

    // Phone number validation for both staff and parent phone numbers (if provided)
    const phoneNumberIndex = headers.indexOf(isStaff ? "phone_number" : "phone_numberp");
    const studentPhoneNumberIndex = headers.indexOf("student_phone_number");
    const phoneNumber = row[phoneNumberIndex];
    const studentPhoneNumber = row[studentPhoneNumberIndex];

    // Helper function to validate phone numbers and remove parentheses
    const isValidPhoneNumber = (number) => {
      if (typeof number !== "string") {
        number = String(number); // Convert to string if it's not already a string
      }
      const cleanedNumber = number.replace(/[()]/g, ""); // Remove parentheses if any
      return (
        /^\+2519\d{8}$/.test(cleanedNumber) ||
        /^\+2517\d{8}$/.test(cleanedNumber) ||
        /^09\d{8}$/.test(cleanedNumber) ||
        /^07\d{8}$/.test(cleanedNumber)
      );
    };

    // Validate parent phone number
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      throw new Error(
        `Invalid parent phone number format on row ${
          i + 1
        }. Phone numbers must looks like this (+2519********), (+2517********)`
      );
    }

    // Validate student phone number
    if (studentPhoneNumber && !isValidPhoneNumber(studentPhoneNumber)) {
      throw new Error(
        `Invalid student phone number format on row ${
          i + 1
        }. Phone numbers must looks like this (+2519********,) (+2517********),`
      );
    }

    // Check if phone_numberp (parent phone number) is provided and validate its format
    const parentPhoneNumberIndex = headers.indexOf("phone_numberp");
    const parentPhoneNumber = row[parentPhoneNumberIndex];
    if (parentPhoneNumber && !isValidPhoneNumber(parentPhoneNumber)) {
      throw new Error(
        `Invalid parent phone number format on row ${
          i + 1
        }.Phone numbers must looks like this (+2519********,) (+2517********),`
      );
    }

    // Optionally handle other validations for the fields
    const isPremiumIndex = headers.indexOf("student_is_premium");
    const studentIsPremium = row[isPremiumIndex];
    if (studentIsPremium && !["yes", "no"].includes(studentIsPremium.toLowerCase())) {
      throw new Error(
        `Row ${
          i + 1
        }: Invalid value for "student_is_premium". Expected "yes" or "no", got "${studentIsPremium}"`
      );
    }
  }

  // If all checks pass, return true indicating the file is valid
  return true;
};
