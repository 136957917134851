import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Checkbox, List, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import { getClassBySchool } from "utils/requests/class/getClass";
import { X } from "lucide-react";
import { getTeacherClassByClassId } from "utils/requests/teachers/assignClass";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const EditTeacherClassModal = ({ teacher, onSave, onClose }) => {
  const [subject, setSubject] = useState(teacher.subject_name);
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getTeacherClassByClassId(teacher.teacher_id);
      if (result.success) {
        setAssignedClasses(result.data.map((classItem) => classItem.class_id));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Sync selectedClasses with assignedClasses when classData is loaded
    const initiallySelectedClasses = classData.filter((classItem) =>
      assignedClasses.includes(classItem.class_id)
    );
    setSelectedClasses(initiallySelectedClasses);
  }, [classData, assignedClasses]);

  const handleClassSelect = (classItem) => {
    setSelectedClasses((prevSelected) => {
      const isSelected = prevSelected.some((cls) => cls.class_id === classItem.class_id);
      if (isSelected) {
        return prevSelected.filter((cls) => cls.class_id !== classItem.class_id);
      } else {
        return [...prevSelected, classItem];
      }
    });
  };

  const handleSave = () => {
    const newAssignedClasses = selectedClasses.map((cls) => cls.class_id);
    onSave({
      staff_id: teacher.teacher_id,
      class_ids: newAssignedClasses,
    });
  };

  return (
    <Modal open onClose={onClose}>
      <Box sx={modalStyle}>
        <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h6" mb={2}>
            Edit Teacher Assignment
          </MDTypography>
          <X onClick={onClose} className="cursor-pointer" />
        </MDBox>
        <TextField
          fullWidth
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          margin="normal"
          disabled
        />
        <MDBox flex={1} style={{ marginRight: "5%" }}>
          <MDTypography variant="h5" mb={2} textAlign={"left"}>
            Update Classes of {teacher.teacher_name}
          </MDTypography>
          <MDBox
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            <List sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              {classData.map((classItem) => (
                <MDBox
                  key={classItem.class_id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    width: "auto",
                    minWidth: "150px",
                  }}
                >
                  <Checkbox
                    checked={selectedClasses.some((cls) => cls.class_id === classItem.class_id)}
                    onChange={() => handleClassSelect(classItem)}
                  />
                  <ListItemText primary={`${classItem.grade_level} - ${classItem.section}`} />
                </MDBox>
              ))}
            </List>
          </MDBox>
        </MDBox>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          fullWidth
        >
          Save Changes
        </Button>
      </Box>
    </Modal>
  );
};

EditTeacherClassModal.propTypes = {
  teacher: PropTypes.shape({
    teacher_id: PropTypes.number.isRequired,
    teacher_name: PropTypes.string.isRequired,
    subject_name: PropTypes.string,
    classes_assigned: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classTeacherAssign: PropTypes.array,
};

export default EditTeacherClassModal;
