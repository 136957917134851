import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EditableTable = ({ columns, data, onEdit, onDelete, setValidationErrors }) => {
  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    },
    enableColumnResizing: true,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: onEdit,
    getRowId: (row) => row.id,
    enableRowActions: true,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton style={{ fontSize: "20px" }} onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton style={{ fontSize: "20px", color: "#FF8080" }} onClick={() => onDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

EditableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
  setValidationErrors: PropTypes.func,
};

export default EditableTable;
