import React, { useState, useRef } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { createExam } from "../../utils/requests/exam/createexam";
import PropTypes from "prop-types";
import { X } from "lucide-react";

function Exams({ onExamLoad }) {
  const [examList, setExamList] = useState([{ examName: "", examResult: "", description: "" }]);
  const refs = useRef({});

  const handleInputChange = (index, field, value) => {
    const newExams = [...examList];
    newExams[index][field] = value;
    setExamList(newExams);
  };

  const handleAddExam = () => {
    setExamList([...examList, { examName: "", examResult: "", description: "" }]);
  };

  const handleRemoveExam = (index) => {
    setExamList(examList.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (examList.some((exam) => !exam.examName || !exam.examResult || !exam.description)) {
      snackbar("error", "Please fill out all fields for each exam.");
      return;
    }

    const examsData = examList.map((exam) => ({
      exam_name: exam.examName,
      max_result: exam.examResult,
      description: exam.description,
    }));
    try {
      const res = await createExam(examsData);
      console.log("exam res", JSON.stringify(res, null, 2));
      if (res.success) {
        snackbar("success", res.message);
        setExamList([{ examName: "", examResult: "", description: "" }]);
        onExamLoad();
      } else {
        snackbar("error", res.message);
        onExamLoad();
        setExamList([{ examName: "", examResult: "", description: "" }]);
      }
    } catch (error) {
      console.log("error", error);
      snackbar("error", `Failed to create exams`);
    }
  };

  const handleKeyDown = (e, nextRefName, index) => {
    if (e.key === "Enter" && refs.current[nextRefName + index]) {
      refs.current[nextRefName + index].focus();
    }
  };

  return (
    <Card sx={{ height: "auto", width: "100%" }}>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6" fontWeight="medium">
            Create Exam
          </MDTypography>
          <IconButton color="info" onClick={handleAddExam}>
            <AddIcon />
          </IconButton>
        </MDBox>
        <MDBox>
          {examList.map((exam, index) => (
            <React.Fragment key={index}>
              <MDBox
                display="flex"
                justifyContent="flex-start"
                gap={2}
                px={2}
                mt={2}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                }}
              >
                <MDInput
                  label="Exam Name"
                  name={`examName-${index}`}
                  inputRef={(el) => (refs.current[`examName${index}`] = el)}
                  value={exam.examName}
                  onChange={(e) => handleInputChange(index, "examName", e.target.value)}
                  sx={{ width: { xs: "100%", sm: "45%" } }}
                  onKeyDown={(e) => handleKeyDown(e, "examResult", index)}
                  required
                />

                <MDInput
                  label="Exam Result"
                  name={`examResult-${index}`}
                  value={exam.examResult}
                  inputRef={(el) => (refs.current[`examResult${index}`] = el)}
                  type="number"
                  onChange={(e) => handleInputChange(index, "examResult", e.target.value)}
                  sx={{ width: { xs: "100%", sm: "45%" } }}
                  onKeyDown={(e) => handleKeyDown(e, "description", index)}
                  required
                />

                {index > 0 && (
                  <IconButton color="error" onClick={() => handleRemoveExam(index)}>
                    <X />
                  </IconButton>
                )}
              </MDBox>

              <MDBox display="flex" justifyContent="flex-start" px={2} mt={2}>
                <MDInput
                  label="Description"
                  name={`description-${index}`}
                  inputRef={(el) => (refs.current[`description${index}`] = el)}
                  value={exam.description}
                  onChange={(e) => handleInputChange(index, "description", e.target.value)}
                  sx={{ width: "91.5%" }}
                  multiline
                  rows={2}
                  required
                />
              </MDBox>
            </React.Fragment>
          ))}

          <MDButton
            color="info"
            variant="contained"
            sx={{ mt: 3, width: "20%", ml: "15%" }}
            onClick={handleSubmit}
          >
            Submit
          </MDButton>
        </MDBox>
      </CardContent>
    </Card>
  );
}

Exams.propTypes = {
  onExamLoad: PropTypes.func.isRequired,
};

export default Exams;
