/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDButton from "components/MDButton";
import { useEffect, useState, useRef } from "react";
import "react-day-picker/dist/style.css";
import { SnackbarProvider } from "notistack";
import { updateTeacher } from "../../../utils/requests/teachers/teacherspost";
import { getSubject } from "../../../utils/requests/subjects/subjectRequests";
import { LoadinSpiner } from "components/LoadingSpinner";
import { snackbar } from "../../../components/awesome_snackbar/snackbar";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { getClassBySchool } from "utils/requests/class/getClass";
import { educationLevels } from "helper/educationLevelData";
import { validatePhone } from "helper/phoneValidation";
import { roles } from "helper/educationLevelData";

const StaffEditorModal = ({ onClose, teacher, routeName }) => {
  const [formData, setFormData] = useState({
    phone: "",
    firstname: "",
    lastname: "",
    email: "",
    subject: null,
    educationallevel: "",
    role: "",
    grade_level: null,
    grade_section: null,
    teacherrole: "",
  });
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const firstNameInputRef = useRef(null);
  useEffect(() => {
    handleSubjectFetch();

    setFormData({
      ...formData,
      firstname: teacher.first_name || "",
      lastname: teacher.last_name || "",
      email: teacher.email || "",
      subject: teacher.subject_id || null,
      educationallevel: teacher.educational_level || "",
      role: teacher.role || null,
      phone: teacher.phone_number || null,
    });

    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
    }
  }, [teacher, routeName, onClose]);

  useEffect(() => {
    const fetchClassData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
    };
    fetchClassData();
  }, []);

  console.log("teacher", JSON.stringify(teacher, null, 2));
  const handleGradeLevelChange = (e) => {
    const selectedGrade = Number(e.target.value);
    setFormData((prev) => ({
      ...prev,
      grade_level: selectedGrade,
      grade_section: "",
    }));
    const sectionsForGrade = classData
      .filter((cls) => cls.grade_level === selectedGrade)
      .map((cls) => cls.section);
    setFilteredSections(sectionsForGrade);
  };

  const validateStaff = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = "First name is required";
    if (!formData.lastname) newErrors.lastname = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    const phoneError = validatePhone(formData.phone);
    if (phoneError) newErrors.phone = phoneError;
    if (!formData.educationallevel) newErrors.educationallevel = "Educational level is required";
    if (!formData.role) newErrors.role = "Role is required";
    if (formData.role === "teacher" && !formData.teacherrole) {
      newErrors.teacherrole = "Teacher role is required for teachers";
    }
    setValidationErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async () => {
    const errors = validateStaff();
    if (Object.keys(errors).length > 0) return; // Prevent submission if errors exist

    const response = await updateTeacher({ staff_id: teacher.staff_id, ...formData });
    console.log("response: " + JSON.stringify(response, null, 2));
    if (response.success) {
      snackbar("success", "Teacher edited successfully!");
      onClose();
    } else {
      snackbar("error", "Failed to save teacher!");
    }
  };

  const handleSubjectFetch = async () => {
    setIsLoading(true);
    const response = await getSubject();
    setIsLoading(false);
    if (response.success) {
      setSubjects(response.data);
    }
  };

  const handleKeyPress = (event, nextFieldId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(nextFieldId)?.focus();
    }
  };

  return (
    <MDBox display="flex" justifyContent="space-around">
      <MDBox pt={4} flex={1} mx={3}>
        <SnackbarProvider />
        <MDBox mb={2}>
          <MDInput
            ref={firstNameInputRef}
            value={formData.firstname}
            id="first-name"
            label="First name"
            style={{ width: "45%" }}
            onKeyPress={(event) => handleKeyPress(event, "last-name")}
            onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
            error={!!validationErrors.firstname}
            helperText={validationErrors.firstname}
          />
          <MDInput
            value={formData.lastname}
            id="last-name"
            label="Last name"
            style={{ width: "45%", marginLeft: "5%" }}
            onKeyPress={(event) => handleKeyPress(event, "phone_input")}
            onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
            error={!!validationErrors.lastname}
            helperText={validationErrors.lastname}
          />
        </MDBox>
        <MDBox mb={2}>
          <PhoneInput
            id="phone_input"
            country={"et"} // Sets the default country to Ethiopia
            value={formData.phone}
            placeholder="+ (251) 985-042-445"
            onChange={(numbers) => setFormData({ ...formData, phone: numbers })}
            preferredCountries={["et", "ke", "dj", "sd"]}
            enableSearch={true}
            inputStyle={{
              width: "95%",
              height: "45px",
            }}
            autocompleteSearch={true}
            countryCodeEditable={true} // Allow editing of both the country code and the phone number
            onKeyPress={(event) => handleKeyPress(event, "email")}
            isInvalid={!!validationErrors.phone}
          />
          {validationErrors.phone && (
            <Typography variant="body2" color="error">
              {validationErrors.phone}
            </Typography>
          )}
        </MDBox>
        <MDBox>
          <MDInput
            value={formData.email}
            id="email"
            type="email"
            label="Email"
            style={{ width: "95%" }}
            onKeyPress={(event) => handleKeyPress(event, "subject")}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />
        </MDBox>
        <select
          id="educational_level"
          value={formData.educationallevel}
          onChange={(e) => setFormData({ ...formData, educationallevel: e.target.value })}
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Select Educational Level</option>
          {educationLevels.map((level) => (
            <option key={level.value} value={level.value}>
              {level.label}
            </option>
          ))}
        </select>
        {validationErrors.educationallevel && (
          <Typography color="error" style={{ fontSize: "12px", marginTop: "5px" }}>
            {validationErrors.educationallevel}
          </Typography>
        )}

        <select
          id="role"
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Select Role</option>
          {roles.map((role) => (
            <option key={role.value} value={role.value}>
              {role.label}
            </option>
          ))}
        </select>
        {validationErrors.role && (
          <Typography color="error" style={{ fontSize: "12px", marginTop: "5px" }}>
            {validationErrors.role}
          </Typography>
        )}
        {formData.role === "teacher" && (
          <MDBox mt={2} pl={2}>
            <Typography component="p" style={{ fontSize: "16px" }}>
              Teacher Role
            </Typography>
            <FormControl style={{ marginLeft: "0%" }}>
              <RadioGroup
                aria-label="teacherrole"
                row
                name="teacherrole"
                value={formData.teacherrole}
                onChange={(e) => setFormData({ ...formData, teacherrole: e.target.value })}
              >
                <FormControlLabel
                  value="subjectTeacher"
                  control={<Radio />}
                  label="Subject Teacher"
                />
                <FormControlLabel
                  value="guidanceTeacher"
                  control={<Radio />}
                  label="Guidance Teacher"
                />
              </RadioGroup>
            </FormControl>
          </MDBox>
        )}
        <MDBox mt={2}>
          <Typography component="p" style={{ fontSize: "16px" }}>
            Subjects
          </Typography>
          <select
            id="subject"
            value={formData.subject}
            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
            style={{
              width: "95%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 10,
            }}
            disabled={formData.role === "registrar" || formData.role === "encoder"}
          >
            <option value="">Select Subject</option>
            {!isLoading ? (
              subjects.map((subject) => (
                <option key={subject.subject_id} value={subject.name}>
                  {subject.name}
                </option>
              ))
            ) : (
              <LoadinSpiner />
            )}
          </select>
        </MDBox>
        <MDBox mb={2} display="flex" justifyContent="spacebteween">
          <select
            id="grade_level"
            value={formData.grade_level}
            onChange={handleGradeLevelChange}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 10,
            }}
            disabled={
              formData.role === "registrar" ||
              formData.role === "encoder" ||
              formData.teacherrole === "" ||
              formData.teacherrole === "subjectTeacher"
            }
          >
            <option value="">Select Grade</option>
            {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </select>
          <select
            id="grade_section"
            value={formData.grade_section}
            onChange={(e) => setFormData({ ...formData, grade_section: e.target.value })}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 10,
              marginLeft: "5%",
            }}
            disabled={
              filteredSections.length === 0 ||
              formData.role === "registrar" ||
              formData.role === "encoder"
            }
          >
            <option value="">Select Section</option>
            {filteredSections.map((section) => (
              <option key={section} value={section}>
                {section}
              </option>
            ))}
          </select>
        </MDBox>
        <MDBox mb={2} display="flex" justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleSubmit}
            style={{ width: "150px" }}
          >
            Edit
          </MDButton>
          <MDButton
            variant="gradient"
            color="error"
            onClick={onClose}
            style={{ marginLeft: "10px", width: "150px" }}
          >
            Close
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default StaffEditorModal;
