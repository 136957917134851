import Snackbar from "awesome-snackbar";
export const snackbar = async (status, messsage) => {
  if (status === "success")
    return new Snackbar(`${messsage}`, {
      theme: "light",
      position: "top-center",
      style: {
        container: [
          ["background-color", "green"],
          ["color", "white"],
        ],
      },
    });
  else if (status === "error")
    return new Snackbar(`${messsage}`, {
      theme: "light",
      position: "top-center",
      style: {
        container: [
          ["background-color", "red"],
          ["color", "white"],
        ],
      },
    });
};
