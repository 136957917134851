import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { LoadinSpiner } from "components/LoadingSpinner";
import MessageSend from "layouts/billing/components/BroadCasting";
import BoadCastList from "layouts/billing/components/BoadCastList";
import { useState } from "react";
import { BroadcastMessage } from "utils/requests/message/createmessage";
import Snackbar from "awesome-snackbar";
import CryptoJS from "crypto-js"; // Import CryptoJS

function Billing() {
  const [success, setSuccess] = useState(false);
  let students = [];
  let braodcastJson = {
    message: "",
    receiver_list: students,
  };
  const [sending, setSending] = useState(false);

  const broadCast = async (message) => {
    braodcastJson.message = message;
    braodcastJson.receiver_list = students;

    if (students.length === 0 || message.length === 0) {
      new Snackbar(`Please select students before sending or please write message.`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
      return;
    }

    setSending(true);

    // Encrypt the message before sending
    const secretKey = process.env.REACT_APP_SECRET_KEY; // Make sure to set this in your environment variables
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(braodcastJson), secretKey).toString();

    try {
      let response = await BroadcastMessage({ data: encryptedData }); // Send encrypted data
      setSending(false);
      if (response) {
        new Snackbar(`Message broadcasted successfully`, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "green"],
              ["color", "white"],
            ],
          },
        });
        setSuccess(true);
        students = [];
        braodcastJson = { message: "", receiver_list: [] };
      } else {
        new Snackbar(``, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "red"],
              ["color", "white"],
            ],
          },
        });
        students = [];
        braodcastJson = { message: "", receiver_list: [] };
      }
    } catch (error) {
      setSending(false);
      new Snackbar(`Error: ${error.message}`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };

  const addToList = (id, isAdding, isClear) => {
    if (!isClear) {
      if (isAdding) {
        if (typeof id !== "number") students.push(id);
      }
      if (!isAdding) {
        students = students.filter((item) => item !== id);
      }
    } else {
      students = [];
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid item xs={12}>
                <MessageSend handler={broadCast} success={success} />
                {sending && <LoadinSpiner />}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <BoadCastList handler={addToList} success={success} is_sending={sending} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
