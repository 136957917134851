import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AttendanceList from "./attendancelist";
import { BroadcastMessage } from "utils/requests/message/createmessage";
import Snackbar from "awesome-snackbar";
import { ArrowBack } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import AddIcon from "@mui/icons-material/Add";
import { getAttendanceByClass } from "utils/requests/attendance/attendancepost";
import { profile } from "context/profile";
import { getMRT_RowSelectionHandler, MaterialReactTable } from "material-react-table";
import EthiopianCalendar from "./attendancedetail";
import { LoadinSpiner } from "components/LoadingSpinner";
export async function fetchTableData() {
  let columns = [
    {
      accessorKey: "student_name",
      header: "Student Name",
      size: 300,
    },
    {
      accessorKey: "class",
      header: "Class",
      size: 150,
    },
    {
      accessorKey: "present_attendance",
      header: "Present",
      size: 150,
    },
    {
      accessorKey: "total_attendance",
      header: "Total Attendance",
      size: 250,
    },
    {
      accessorKey: "average_present",
      header: "Attendance Percentage",
      size: 300,
    },
  ];
  return {
    column: columns,
  };
}

function Attendance() {
  const [students, setStudents] = useState([]); // State to manage selected students
  const [snackbar, setSnackbar] = useState(null); // State to handle snackbar
  const [columns, setColumns] = useState([]); // Initialize as an empty array
  const [rows, setRows] = useState([]); // Initialize as an empty array
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState("");
  const [isRendered, setIsRendered] = useState(false);
  const [isStudentSelected, setIsStudentSelected] = useState(false); // Manage student selection
  const [selectedRows, setSelectedRows] = useState({}); // Track selected rows
  const [student_id, setStudent_id] = useState(null);
  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const rowData = await getAttendanceByClass();
        if (rowData.success && rowData.data && Array.isArray(rowData.data)) {
          setRows(rowData.data);
        } else {
          setError(rowData.message);
        }
      } catch (error) {
        setError(error.response.data.message);
      }
      const tableData = await fetchTableData();
      setColumns(tableData.column || []);
    };
    fetchAttendance();
  }, [isRendered]);

  let braodcastJson = {
    message: "",
    receiver_list: students,
  };

  const broadCast = async (message) => {
    braodcastJson.message = message;
    braodcastJson.receiver_list = students;

    if (students.length === 0) {
      setSnackbar(
        new Snackbar(`Please select students before sending.`, {
          theme: "light",
          position: "top-center",
          style: { container: [["background-color", "red"]] },
        })
      );
      return;
    }

    await BroadcastMessage(braodcastJson);
  };

  const addToList = (id, isAdding, isClear) => {
    if (isClear) {
      setStudents([]); // Clear the list
    } else {
      setStudents((prev) => (isAdding ? [...prev, id] : prev.filter((item) => item !== id)));
    }
  };

  const handleOnClick = () => {
    setAddVisibility(!addVisibility);
  };

  // Handle back button to go back from form to table view
  const arrowBackHandler = () => {
    setAddVisibility(!addVisibility);
    setIsRendered((prev) => !prev);
  };

  const handleStudentSelect = (row) => {
    const rowId = row.id;
    const updatedSelection = { ...selectedRows };

    // Toggle row selection
    if (updatedSelection[rowId]) {
      delete updatedSelection[rowId];
    } else {
      updatedSelection[rowId] = row;
    }

    setSelectedRows(updatedSelection);
    setIsStudentSelected(Object.keys(updatedSelection).length > 0);
    setStudent_id(row.original.student_id);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Attendances
              </MDTypography>

              {profile.class_id && (
                <MDBox display="flex" alignItems="center">
                  <Avatar
                    style={{
                      backgroundColor: "white",
                      padding: 8,
                      cursor: "pointer",
                    }}
                  >
                    <IconButton style={{ padding: 0 }}>
                      {addVisibility ? (
                        <ArrowBack style={{ color: "#1976d2" }} onClick={arrowBackHandler} />
                      ) : (
                        <AddIcon style={{ color: "#1976d2" }} onClick={handleOnClick} />
                      )}
                    </IconButton>
                  </Avatar>
                </MDBox>
              )}
            </MDBox>

            {addVisibility ? (
              <AttendanceList handler={addToList} />
            ) : (
              <>
                {isStudentSelected && student_id ? (
                  <MDBox mt={2} ml={2} mr={2}>
                    <EthiopianCalendar
                      student_id={student_id}
                      setIsStudentSelected={setIsStudentSelected}
                    />
                  </MDBox>
                ) : (
                  <MDBox mt={2} ml={2} mr={2}>
                    {error ? (
                      <p className="text-gray-600 font-normal text-center capitalize text-[13px]">
                        {error}
                      </p>
                    ) : columns.length > 0 && rows.length > 0 ? (
                      <MaterialReactTable
                        columns={columns}
                        data={rows}
                        enableRowNumbers
                        enableColumnResizing
                        // enableRowSelection={true}
                        enableBatchRowSelection={false}
                        enableSelectAll={false}
                        muiTableBodyRowProps={({ row, staticRowIndex, table }) => ({
                          onClick: (event) => {
                            getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
                            handleStudentSelect(row);
                          },
                          sx: { cursor: "pointer" },
                        })}
                      />
                    ) : (
                      <p className="text-gray-600 font-normal text-center capitalize text-[13px]">
                        no attendance
                      </p>
                    )}
                  </MDBox>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
export default Attendance;
