import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import AlertTitle from "@mui/material/AlertTitle";
import MDTypography from "components/MDTypography";
import { updateNotification } from "utils/requests/notification/notification";
import Snackbar from "awesome-snackbar";
import { formatDistanceToNow } from "date-fns";

function NotificationCard(props) {
  const { request_id, message, name, date, type, role, exams, onStatusChange } = props;
  const formattedDate = formatDistanceToNow(new Date(date));

  const handleClick = async (requestId, status) => {
    try {
      const response = await updateNotification(requestId, status);

      if (response.success) {
        new Snackbar(`Request ${status === "accepted" ? "approved" : "declined"} successfully!`, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", status === "accepted" ? "green" : "red"],
              ["color", "white"],
            ],
          },
        });
        onStatusChange(); // Call this only on successful update
      } else {
        throw new Error("Failed to update request");
      }
    } catch (error) {
      new Snackbar(`Request failed!`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "red"],
            ["color", "white"],
          ],
        },
      });
    }
  };

  return (
    <Alert sx={{ bgcolor: "#F0F2F5", marginBottom: "10px", borderRadius: "20px" }} icon={false}>
      <AlertTitle style={{ display: "flex" }}>
        <div style={{ position: "relative" }}>
          <span style={{ fontSize: "16px" }}>
            {name}&nbsp;
            <span style={{ fontSize: "14px", color: "#C7C7C7" }}>/&nbsp;{role}</span>
            <span style={{ fontSize: "14px", color: "#C7C7C7" }}>/&nbsp;{type}</span>
          </span>
        </div>
        <MDTypography
          color="secondary"
          style={{ position: "absolute", right: "0", marginRight: "10%", fontSize: "12px" }}
        >
          {formattedDate}
        </MDTypography>
      </AlertTitle>
      <p style={{ marginTop: "10px", marginBottom: "20px", fontSize: "14px" }}>{message}</p>

      {exams.length > 0 && (
        <MDBox>
          {exams.map((exam, index) => (
            <p key={index} style={{ fontSize: "12px", margin: "0 0 20px 0" }}>
              Student {exam.student_name} (Grade: {exam.grade_level} {exam.section}, Mark:{" "}
              {exam.mark}) - {exam.examType_name}
            </p>
          ))}
        </MDBox>
      )}

      <MDBox display="flex">
        <MDButton
          style={{
            background: "white",
            color: "green",
            transition: "background-color 0.3s, color 0.3s",
          }}
          onClick={() => handleClick(request_id, "accepted")}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "green";
            e.currentTarget.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
            e.currentTarget.style.color = "green";
          }}
        >
          Approve
        </MDButton>
        <MDButton
          style={{
            marginLeft: "20px",
            background: "white",
            color: "red",
            transition: "background-color 0.3s, color 0.3s",
          }}
          onClick={() => handleClick(request_id, "declined")}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "red";
            e.currentTarget.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
            e.currentTarget.style.color = "red";
          }}
        >
          Decline
        </MDButton>
      </MDBox>
    </Alert>
  );
}

NotificationCard.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string,
  request_id: PropTypes.string.isRequired,
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      student_id: PropTypes.string.isRequired,
      student_name: PropTypes.string.isRequired,
      grade_level: PropTypes.number.isRequired,
      mark: PropTypes.string.isRequired,
      examType_name: PropTypes.string.isRequired,
    })
  ),
  onStatusChange: PropTypes.func.isRequired,
};

NotificationCard.defaultProps = {
  exams: [],
};

export default NotificationCard;
