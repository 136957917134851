import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getClassBySchool } from "../../utils/requests/class/getClass";
import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import Classes from "./addclass";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { LoadinSpiner } from "components/LoadingSpinner";
import EditableTable from "helper/editableTable";
import { deleteClass } from "utils/requests/class/createClass";
import { updateClass } from "utils/requests/class/createClass";
import { snackbar } from "components/awesome_snackbar/snackbar";
import ConfirmationDialog from "helper/comfirmationOfDelete";

// Validation function for each class field
const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

// Validation function to check all fields in a class object
const validatesClass = (data) => {
  return {
    section: !validateRequired(data.section) ? "Section is required" : "",
    capacity: !validateRequired(data.capacity) ? "Capacity is required" : "",
    grade_level: !validateRequired(data.grade_level) ? "Grade level is required" : "",
    building_name: !validateRequired(data.building_name) ? "Building name is required" : "",
  };
};

// Columns configuration

export default function ClassPage() {
  const [rows, setRows] = useState([]);
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [is_refresh, setIsRefresh] = useState(false);

  const columnsConfig = [
    {
      accessorKey: "grade_level",
      header: "Grade Level",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.grade_level,
        helperText: validationErrors.grade_level,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, grade_level: undefined })),
      },
    },
    {
      accessorKey: "section",
      header: "Section",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.section,
        helperText: validationErrors.section,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, section: undefined })),
      },
    },
    {
      accessorKey: "building_name",
      header: "Building Name",
      size: 200,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.building_name,
        helperText: validationErrors.building_name,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, building_name: undefined })),
      },
    },
    {
      accessorKey: "capacity",
      header: "Capacity",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.capacity,
        helperText: validationErrors.capacity,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, capacity: undefined })),
      },
    },
  ];
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getClassBySchool();
      setRows(response?.data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [is_refresh]);

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validatesClass(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    const class_id = row.original?.class_id;

    if (class_id) {
      try {
        const response = await updateClass({ class_id, data: values });

        if (response.success) {
          snackbar("success", "Class updated successfully");
          setIsRefresh(!is_refresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", "Failed to update class");
        }
      } catch {
        snackbar("error", "Failed to update class");
      }
    }
  };

  const handleDelete = async () => {
    const class_id = deleteRow?.original?.class_id;
    if (class_id) {
      try {
        const response = await deleteClass(class_id);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to delete class");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
    fetchData();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Classes
              </MDTypography>

              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton style={{ padding: 0 }} onClick={toggleAddVisibility}>
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {addVisibility ? (
              <Classes />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : (
                  <>
                    {error && <MDTypography color="error">{error}</MDTypography>}
                    {rows.length > 0 ? (
                      <EditableTable
                        columns={columnsConfig}
                        data={rows}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                      />
                    ) : (
                      <p className="flex justify-center items-center">No class data available.</p>
                    )}
                  </>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete Class"
        message="Are you sure you want to delete this class? This action will affect associated data."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
}
