import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion";
import { MessageSquare, Brain, Users, TrendingUp, Gift, ChevronDown } from "lucide-react";
import { Link, Navigate } from "react-router-dom";
import Team from "./team";
import Pricing from "./pricing";
import Footers from "./footers";
import ContactForm from "./contactForm";
import Features from "./features";
import { ReactTyped } from "react-typed";
import styles from "../styles/styles.css";
import { useNavigate } from "react-router-dom";
const StarField = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const stars = [];
    const numStars = 100;

    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1.5,
        alpha: Math.random(),
        velocity: Math.random() * 0.02,
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";

      stars.forEach((star) => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 3);
        ctx.fill();

        star.alpha += star.velocity;
        star.y += star.velocity;

        if (star.y > canvas.height) {
          star.y = 0;
        }
        if (star.alpha > 1) {
          star.alpha = 0;
        }

        ctx.globalAlpha = star.alpha;
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} className="absolute inset-0" />;
};

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);

      const sections = ["home", "features", "pricing", "team", "contact"];
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <motion.nav
      className="fixed top-0 left-0 right-0 z-50"
      initial={{ backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none" }}
      animate={{
        backgroundColor: isScrolled ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)",
        boxShadow: isScrolled ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
      }}
      transition={{ duration: 0.3 }}
    >
      <div className="container mx-auto px-8">
        <div className="flex justify-between items-center py-4">
          <motion.a
            href="#home"
            className="flex items-center"
            onClick={() => scrollToSection("home")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-ct-u0A9nyQtEd2TTXzF16aTV9WEydz4el.png"
              alt="Askuala Link Logo"
              className="h-20 w-auto mr-2"
            />
            <span className="text-2xl font-bold text-white font-poppins">Askuala Link</span>
          </motion.a>
          <div className="hidden md:flex space-x-4">
            {["Home", "Features", "Pricing", "Team", "Contact"].map((item) => (
              <motion.a
                key={item}
                href={`#${item.toLowerCase()}`}
                onClick={() => scrollToSection(item.toLowerCase())}
                className={`text-lg font-medium transition-colors duration-300 relative ${
                  activeSection === item.toLowerCase() ? "text-blue-400" : "text-gray-300"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {item}
                <motion.div
                  className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-400"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                />
              </motion.a>
            ))}
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

// Feature component
const Feature = ({ icon: Icon, title, description }) => (
  <motion.div
    className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl"
    whileHover={{ scale: 1.05, rotateY: 5 }}
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-white-800 text-center">{description}</p>
  </motion.div>
);

Feature.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
// Pricing component
const PricingPlan = ({ title, price, features }) => (
  <motion.div
    className="flex flex-col p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl"
    whileHover={{ scale: 1.05, rotateY: 5 }}
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="text-2xl font-bold mb-4 text-gray-800">{title}</h3>
    <p className="text-4xl font-bold mb-6 text-gray-800">{price}</p>
    <ul className="mb-8">
      {features.map((feature, index) => (
        <motion.li
          key={index}
          className="flex items-center mb-2 text-gray-700"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <svg
            className="w-4 h-4 mr-2 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          {feature}
        </motion.li>
      ))}
    </ul>
    <motion.button
      className="mt-auto bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onTap={() => {
        // console.log("login success");
        navigate("/login");
      }}
    >
      <a href="/login">Get Started</a>
    </motion.button>
  </motion.div>
);

PricingPlan.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Main component
const AskualaLinkWebsite = () => {
  const { scrollYProgress } = useScroll();
  const scrollIndicatorHeight = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  const heroTexts = [
    "Bridge the Gap: Teachers and Parents",
    "Free Solutions for Schools!",
    "What are you waiting for? Contact Us",
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % heroTexts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const scrollToNextSection = () => {
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();
  return (
    <div className="relative bg-gray-900 text-white">
      <Navigation />
      <div className="fixed top-0 right-0 w-1 h-full bg-gray-700 z-50">
        <motion.div className="w-full bg-blue-500" style={{ height: scrollIndicatorHeight }} />
      </div>

      <section
        id="home"
        className="min-h-screen flex items-center justify-center relative overflow-hidden"
      >
        <StarField />
        <div className="relative z-10 text-center max-w-4xl mx-auto px-4 flex flex-col items-center">
          <AnimatePresence mode="wait">
            <motion.h1
              key={currentTextIndex}
              className="text-5xl md:text-7xl font-bold mb-6 text-white font-poppins"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {heroTexts[currentTextIndex]}
            </motion.h1>
          </AnimatePresence>
          <motion.p
            className="text-xl md:text-2xl mb-12 text-gray-300 max-w-2xl font-poppins"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Empower your school community with seamless communication between teachers and
            parents—all at no cost. We bridge the gap, and its completely free!
          </motion.p>
          <div className="flex flex-col md:flex-row items-center gap-6">
            <motion.button
              className="bg-blue-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-blue-600 transition-colors duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              onClick={() => {
                // console.log("login success");
                navigate("/login");
              }}
            >
              Get Started
            </motion.button>
            <motion.div
              className="cursor-pointer"
              animate={{ y: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
              onClick={scrollToNextSection}
            >
              <ChevronDown className="w-10 h-10 text-white" />
            </motion.div>
          </div>
        </div>
      </section>

      <section id="features" className="py-20 ">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center mb-12 text-white"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            Our Features
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <Feature
              icon={MessageSquare}
              title="SMS Reporting"
              description="Keep parents informed with automated SMS reports on student attendance, grades, and more."
            />
            <Feature
              icon={Brain}
              title="AI Suggestions"
              description="Leverage AI to offer parents personalized insights and suggestions for student success."
            />
            <Feature
              icon={Users}
              title="Roster Management"
              description="Easily manage school rosters with our comprehensive dashboard  designed for simplicity and accuracy."
            />
            <Feature
              icon={TrendingUp}
              title="Student Performance Tracking"
              description="Track student performance with real-time data to help teachers and parents stay informed."
            />
            <Feature
              icon={Gift}
              title="Teacher Bonuses"
              description="Reward teachers with bonuses for assisting with data encoding and administrative tasks."
            />
            <Feature
              icon={MessageSquare}
              title="Parent-Teacher Communication"
              description="Facilitate seamless communication between parents and teachers through our integrated platform."
            />
          </div>
        </div>
      </section>
      <section id="pricing">
        <Pricing />
      </section>
      <section id="team">
        <Team />
      </section>

      <section id="contact" className="py-20 bg-gray-900">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center mb-12 text-white"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            Contact Us
          </motion.h2>
          <motion.form
            className="max-w-lg mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-300 font-bold mb-2">
                {" "}
                Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-300 font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-300 font-bold mb-2">
                Message
              </label>
              <textarea
                id="message"
                rows={4}
                className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
            </div>
            <motion.button
              type="submit"
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Send Message
            </motion.button>
          </motion.form>
        </div>
      </section>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-2xl font-bold mb-4">Askuala Link</h3>
              <p>Empowering education through innovative solutions.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul>
                <li>
                  <Link href="#home" className="hover:text-blue-300">
                    Home
                  </Link>
                </li>
                <li>
                  <Link href="#features" className="hover:text-blue-300">
                    Features
                  </Link>
                </li>
                <li>
                  <Link href="#pricing" className="hover:text-blue-300">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link href="#team" className="hover:text-blue-300">
                    Team
                  </Link>
                </li>
                <li>
                  <Link href="#contact" className="hover:text-blue-300">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
              <p>Email: info@askualalink.com</p>
              <p>Phone: +251 90 000 000</p>
              <p>Address: Addis Ababa and Bahirdar, Ethiopia</p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>&copy; {new Date().getFullYear()} Askuala Link. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AskualaLinkWebsite;
