export const educationLevels = [
  { value: "High School", label: "High School" },
  { value: "Diploma", label: "Diploma" },
  { value: "Associate Degree", label: "Associate Degree" },
  { value: "Bachelor of Degree", label: "Bachelor of Degree" },
  { value: "Master of Science", label: "Master of Science (MS)" },
  { value: "Master of Arts", label: "Master of Arts (MA)" },
  { value: "Master of Business Administration", label: "Master of Business Administration (MBA)" },
  { value: "Doctor of Medicine", label: "Doctor of Medicine (MD)" },
  { value: "Doctor of Philosophy", label: "Doctor of Philosophy (PhD)" },
  { value: "Postdoctoral Studies", label: "Postdoctoral Studies" },
  { value: "Certificate", label: "Certificate" },
  { value: "Professional Certification", label: "Professional Certification" },
];
export const roles = [
  { value: "registrar", label: "Registrar" },
  { value: "teacher", label: "Teacher" },
  { value: "encoder", label: "Encoder" },
];
