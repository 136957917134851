import { axiosInstanceAuth } from "config/axios-config";

export const getExamType = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const localResponse = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const response = await axiosAuth.get(`/exam/school`);
    if (response) {
      localResponse.message = response.data.message;
      localResponse.success = response.data.success;
      localResponse.data = response.data.data;
    } else {
      localResponse.message = "Failed to get exam types";
    }
  } catch (error) {
    // // console.log(error);
    localResponse.message = error.data;
  }
  return localResponse;
};
