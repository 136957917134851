import React from "react";
import PropTypes from "prop-types";

const Bubble = ({ message }) => {
  return (
    <div className="flex justify-end mb-2 ">
      {" "}
      {/* Changed justify-start to justify-end */}
      <div
        className="bg-gray-800 text-black text-sm py-2 px-4 rounded-3xl max-w-xs"
        style={{ backgroundColor: "#D3E3FD" }}
      >
        {message}
      </div>
    </div>
  );
};

Bubble.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Bubble;
