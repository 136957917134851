import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AvatarColor from "components/ColorAvater/ColorAvater";
import React, { useEffect, useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { getClassBySchool } from "utils/requests/class/getClass";
import { getOnlyTeachers } from "utils/requests/teachers/teacher";
import { Check, Close } from "@mui/icons-material";
import { CustomHr } from "layouts/attendance/attendancecomponent";
import { createTeacherClass } from "utils/requests/teachers/assignClass";
import MDButton from "components/MDButton";
import { useMediaQuery } from "@mui/material"; // For responsiveness
import { snackbar } from "../../components/awesome_snackbar/snackbar";

const AssignClass = () => {
  const [teachers, setTeachers] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false); // Modal for mobile class selection

  const isMobile = useMediaQuery("(max-width:600px)"); // Check for mobile screen size

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      const result = await getOnlyTeachers();
      if (result.data.success) {
        setTeachers(result.data.data);
      }
    };
    fetchTeachers();
  }, []);

  const filteredTeachers = teachers.filter((teacher) =>
    teacher.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectTeacher = (teacher) => {
    setSelectedTeacher((prevSelected) =>
      prevSelected && prevSelected.staff_id === teacher.staff_id ? null : teacher
    );
    setSelectedClasses([]);

    if (isMobile && teacher) {
      setOpenModal(true); // Open modal on mobile when a teacher is selected
    }
  };

  const handleClassSelect = (classItem) => {
    setSelectedClasses((prevSelected) => {
      if (prevSelected.includes(classItem)) {
        return prevSelected.filter((cls) => cls.class_id !== classItem.class_id);
      } else {
        return [...prevSelected, classItem];
      }
    });
  };

  const handleAssignClasses = async () => {
    if (selectedTeacher && selectedClasses.length > 0) {
      const classAssignments = {
        staff_id: selectedTeacher.staff_id,
        class_ids: selectedClasses.map((cls) => cls.class_id),
      };

      const response = await createTeacherClass(classAssignments);
      if (response.success) {
        snackbar("success", response.message);
        setSelectedClasses([]);
        setSelectedTeacher(null);
        setOpenModal(false); // Close modal on successful assignment
      } else {
        snackbar("error", response.message);
      }
    }
  };

  return (
    <MDBox
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="space-between"
      padding={isMobile ? "16px" : "32px"}
    >
      {/* Teacher Selection Section */}
      <MDBox mb={3} flex={1} mr={isMobile ? 0 : 2}>
        <MDTypography variant="h4" mb={2} textAlign={isMobile ? "center" : "left"}>
          Assign Teacher to Classroom
        </MDTypography>

        {/* Search Field */}
        <TextField
          label="Search Teachers"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb={2}
        />

        {/* Teacher List */}
        <MDBox
          mt={2}
          sx={{
            maxHeight: isMobile ? "250px" : "400px",
            overflowY: "auto",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <List>
            {filteredTeachers.length > 0 ? (
              filteredTeachers.map((teacher, index) => (
                <MDBox key={index}>
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    mt={2}
                    ml={1}
                    onClick={() => handleSelectTeacher(teacher)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          selectedTeacher?.staff_id === teacher.staff_id
                            ? "green"
                            : AvatarColor(teacher.first_name),
                      }}
                    >
                      {selectedTeacher?.staff_id === teacher.staff_id ? (
                        <Check sx={{ color: "white" }} />
                      ) : (
                        teacher.first_name.charAt(0)
                      )}
                    </Avatar>
                    &nbsp;
                    <MDBox>
                      <p style={{ fontSize: 14, margin: 0 }}>
                        {teacher.first_name} {teacher.last_name}
                      </p>
                      <MDTypography
                        fontWeight="light"
                        color="secondary"
                        sx={{ fontSize: "small", textAlign: "left" }}
                      >
                        Subject: {teacher?.subject?.name}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <CustomHr height={2} color="#f0f2f5" />
                </MDBox>
              ))
            ) : (
              <MDTypography
                variant="body1"
                style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
              >
                No teachers found.
              </MDTypography>
            )}
          </List>
        </MDBox>
      </MDBox>

      {/* Class Assignment Section for Non-Mobile */}
      {!isMobile && selectedTeacher && (
        <MDBox flex={1} mt={isMobile ? 3 : 0} style={{ marginRight: isMobile ? 0 : "5%" }}>
          <MDTypography variant="h5" mb={2} textAlign={isMobile ? "center" : "left"}>
            Assign Classes to {selectedTeacher.first_name}
          </MDTypography>
          <MDBox
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            <List>
              {classData.map((classItem) => (
                <ListItem
                  key={classItem.class_id}
                  dense
                  // onClick={() => handleClassSelect(classItem)}
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={selectedClasses.some((cls) => cls.class_id === classItem.class_id)}
                    onChange={() => handleClassSelect(classItem)}
                  />
                  <ListItemText primary={`${classItem.grade_level} - ${classItem.section}`} />
                </ListItem>
              ))}
            </List>
          </MDBox>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: "10%",
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              style={{ width: "50%", cursor: "pointer" }}
              onClick={handleAssignClasses}
            >
              Assign Class
            </MDButton>
          </div>
        </MDBox>
      )}

      {/* Mobile Modal for Class Assignment */}
      {isMobile && (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen>
          <DialogContent>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpenModal(false)}
              aria-label="close"
              sx={{ position: "absolute", right: 16, top: 16 }}
            >
              <Close />
            </IconButton>

            {selectedTeacher && (
              <MDBox>
                <MDTypography variant="h5" mb={2} textAlign="center">
                  Assign Classes to {selectedTeacher.first_name}
                </MDTypography>

                {/* Scrollable Class Selection with Checkboxes */}
                <MDBox
                  sx={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <List>
                    {classData.map((classItem) => (
                      <ListItem
                        key={classItem.class_id}
                        dense
                        onClick={() => handleClassSelect(classItem)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Checkbox
                          checked={selectedClasses.includes(classItem)}
                          onChange={() => handleClassSelect(classItem)}
                        />
                        <ListItemText primary={`${classItem.grade_level} - ${classItem.section}`} />
                      </ListItem>
                    ))}
                  </List>
                </MDBox>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: "10%",
                  }}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ width: "50%", cursor: "pointer" }}
                    onClick={handleAssignClasses}
                  >
                    Assign Class
                  </MDButton>
                </div>
              </MDBox>
            )}
          </DialogContent>
        </Dialog>
      )}
    </MDBox>
  );
};

export default AssignClass;
