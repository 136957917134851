import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
// import Tables from "../../components/tables/table";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getRequestBySender } from "utils/requests/request/createRequest";
import RequestForm from "./requestForm";
import { LoadinSpiner } from "components/LoadingSpinner";
import { MaterialReactTable } from "material-react-table";
import PropTypes from "prop-types"; // Import PropTypes

export const Tables = ({ columns, rows }) => {
  return <MaterialReactTable columns={columns} data={rows} enableColumnResizing />;
};

Tables.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      size: PropTypes.number,
      Cell: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      message_id: PropTypes.string.isRequired,
      type: PropTypes.string,
      message: PropTypes.string,
      status: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ).isRequired,
};

// Define PropTypes for the custom cell rendering
const CustomStatusCell = ({ cell }) => {
  const status = cell.getValue();
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "green";
      case "declined":
        return "red";
      case "pending":
        return "gray";
      default:
        return "gray";
    }
  };

  return <span style={{ color: getStatusColor(status) }}>{status}</span>;
};

CustomStatusCell.propTypes = {
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }).isRequired,
};

export async function fetchTableData() {
  const columns = [
    {
      accessorKey: "type",
      header: "Request Type",
      size: 250,
    },
    {
      accessorKey: "message",
      header: "Message",
      size: 550,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 150,
      Cell: CustomStatusCell, // Use the custom cell component
    },
    {
      accessorKey: "createdAt",
      header: "Created Time",
      size: 150,
    },
  ];

  return {
    column: columns,
  };
}

export default function RequestPage() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [is_render, setIs_rending] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRequestBySender()
      .then((data) => {
        if (data.data) {
          setRows(data.data);
        } else {
          setRows([]);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));

    fetchTableData().then(({ column }) => {
      setColumns(column || []); // Ensure columns is always an array
    });
  }, [is_render]);

  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
    setIs_rending(!is_render);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Requests
              </MDTypography>

              <MDBox display="flex" alignItems="center">
                <Avatar
                  style={{
                    backgroundColor: "white",
                    padding: 8,
                    cursor: "pointer",
                  }}
                >
                  <IconButton style={{ padding: 0 }} onClick={toggleAddVisibility}>
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {addVisibility ? (
              <RequestForm />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : (
                  <>
                    {error && (
                      <MDTypography
                        color="error"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {error.message}
                      </MDTypography>
                    )}
                    {columns && rows && columns.length > 0 && rows.length > 0 ? (
                      <Tables columns={columns} rows={rows} />
                    ) : (
                      <MDTypography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Request data available.
                      </MDTypography>
                    )}
                  </>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
