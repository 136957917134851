import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { fetchDashboard } from "utils/requests/others/dashboard";
import { getNotification } from "../../utils/requests/notification/notification";
import { notificationGetter } from "context/notification";
import { LoadinSpiner } from "components/LoadingSpinner";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [dataJson, setDataJson] = useState(null);

  const navigateTostudents = () => {
    navigate("/students", { replace: true });
  };
  const navigateToteachers = () => {
    navigate("/staffs", { replace: true });
  };
  const setCardBoard = (data) => {
    setDataJson(data?.data || {}); // Ensure dataJson is an object
    setShowBanner(data?.data?.school_plan_id === null || data?.data?.school_plan_id === undefined);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await fetchDashboard(setCardBoard);
      await getNotification();
      setIsLoading(false);
      setNotifications(notificationGetter());
    };
    fetchData();
  }, []);

  if (isLoading) return <LoadinSpiner />;
  return (
    <DashboardLayout>
      <DashboardNavbar notificationCounter={notifications} notificationLists={notifications} />
      <MDBox py={3}>
        {showBanner && (
          <MDBox
            display="flex"
            justifyContent="between"
            alignItems="center"
            bgcolor="yellow.200"
            color="black"
            p={3}
            borderRadius="md"
            boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
            mb={4}
            style={{
              border: "1px solid #fbbf24", // Tailwind yellow-400
              backgroundColor: "#fef9c3", // Tailwind yellow-200
              color: "#92400e", // Tailwind amber-700
              fontWeight: "600",
              fontSize: "1rem",
              position: "relative",
              padding: "1rem 1.25rem",
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>
              Your school plan is currently missing. Please create one to access full system
              features
            </span>
            <button
              onClick={() => setShowBanner(false)}
              style={{
                all: "unset",
                cursor: "pointer",
                color: "#92400e",
                fontSize: "1.25rem",
                fontWeight: "bold",
                padding: "0 0.75rem",
              }}
            >
              ✕
            </button>
          </MDBox>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <button
                onClick={navigateTostudents}
                style={{
                  all: "unset",
                  display: "block",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Students"
                  count={dataJson?.students || 0}
                  percentage={{
                    color: "success",
                    amount: " ",
                    label: "Current year",
                  }}
                />
              </button>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <button
                onClick={navigateToteachers}
                style={{
                  all: "unset",
                  display: "block",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Staff"
                  count={dataJson?.teachers || 0}
                  percentage={{
                    color: "success",
                    amount: " ",
                    label: "Current year",
                  }}
                />
              </button>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Class"
                count={dataJson?.classRooms || 0}
                percentage={{
                  color: "success",
                  label: "Current year",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Revenue"
                count={`${dataJson?.revenue || 0} ETB`}
                percentage={{
                  color: "success",
                  label: "Current year",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <MDBox mb={3}>
            <ReportsLineChart
              color="info"
              title="Yearly Students Number"
              description={
                <>
                  (<strong>+{dataJson?.increaseRate || 0}</strong>) increase in current year.
                </>
              }
              date="updated 4 min ago"
              chart={tasks}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
