import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getTeachersClasses } from "utils/requests/teachers/assignClass";
import AssignClass from "./assignClass";
import { LoadinSpiner } from "components/LoadingSpinner";
import { MaterialReactTable } from "material-react-table";
import EditTeacherClassModal from "./editClass_Of_teacher";
import { createTeacherClass } from "utils/requests/teachers/assignClass";
import { snackbar } from "components/awesome_snackbar/snackbar";

export async function fetchTableData() {
  const columns = [
    {
      accessorKey: "teacher_name",
      header: "Teacher Name",
      size: 400,
    },
    {
      accessorKey: "subject_name",
      header: "Subject Name",
      size: 250,
    },
    {
      accessorKey: "classes_assigned",
      header: "Classes Assigned",
      size: 300,
      Cell: ({ cell }) => cell.getValue().join(", "),
    },
  ];
  return { columns };
}

const useTeacherAssignments = (isRerender) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teacherClass, setTeacherClass] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTeachersClasses();
        if (response?.data?.success && Array.isArray(response.data.data)) {
          setTeacherClass(response.data.data);
          const groupedData = groupClassesByTeacher(response.data.data);
          setRows(groupedData);
        } else {
          throw new Error("Unexpected data format received from the API.");
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isRerender]);

  return { rows, isLoading, error, teacherClass };
};

const groupClassesByTeacher = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const teacherKey = `${item.staff.first_name} ${item.staff.last_name}-${
      item.staff.subject?.name || "N/A"
    }`;
    if (!acc[teacherKey]) {
      acc[teacherKey] = {
        teacher_name: `${item.staff.first_name} ${item.staff.last_name}`,
        subject_name: item.staff.subject?.name || "N/A",
        classes_assigned: [],
        teacher_id: item.staff.staff_id,
      };
    }
    acc[teacherKey].classes_assigned.push(`${item.class.grade_level} ${item.class.section}`);
    return acc;
  }, {});

  return Object.values(groupedData);
};

export default function TeacherAssignments() {
  const [isRerender, setIsRerender] = useState(false);
  const { rows, isLoading, error, teacherClass } = useTeacherAssignments(isRerender);
  const [columns, setColumns] = useState([]);
  const [addVisibility, setAddVisibility] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  useEffect(() => {
    const fetchColumns = async () => {
      const tableData = await fetchTableData();
      setColumns(tableData.columns);
    };
    fetchColumns();
  }, [isRerender]);

  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
    setIsRerender(!isRerender);
  };

  const handleEdit = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const handleSaveChanges = async (updatedData) => {
    try {
      const newClassAssignments = updatedData.class_ids.filter((classId) => {
        return !teacherClass.some((assignedClass) => assignedClass.class_id === classId);
      });
      const removedClassAssignments = teacherClass.filter((assignedClass) => {
        return !updatedData.class_ids.includes(assignedClass.class_id);
      });
      if (newClassAssignments.length === 0 && removedClassAssignments.length === 0) {
        return;
      }

      if (removedClassAssignments.length === 0) {
        return;
      }
      const response = await createTeacherClass(updatedData);
      if (response.success) {
        snackbar("success", response.message);
        setIsRerender(!isRerender);
      } else {
        snackbar("error", response.message);
      }
    } catch (error) {
      snackbar("error", "Failed to create teacher class assignments.");
    } finally {
      setSelectedTeacher(null);
    }
  };

  const renderContent = () => {
    if (isLoading) return <LoadinSpiner />;
    if (error) return <MDTypography color="error">{error.message}</MDTypography>;
    if (rows.length === 0)
      return (
        <p className="flex justify-center items-center text-[14px]">
          No teacher class data available.
        </p>
      );
    return (
      <MaterialReactTable
        columns={columns}
        data={rows}
        enableRowNumbers
        enableColumnResizing
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleEdit(row.original),
          sx: { cursor: "pointer" },
        })}
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Teacher Assignments
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={toggleAddVisibility}
                    aria-label="toggle add class"
                  >
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {addVisibility ? (
              <AssignClass />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {renderContent()}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>

      {/* Render edit modal */}
      {selectedTeacher && (
        <EditTeacherClassModal
          teacher={selectedTeacher}
          onSave={handleSaveChanges}
          onClose={() => setSelectedTeacher(null)}
          classTeacherAssign={teacherClass} // Pass teacherClass to the modal
        />
      )}
    </DashboardLayout>
  );
}
