import React, { useState } from "react";
import PropTypes from "prop-types";
import { Divider, IconButton } from "@mui/material"; // You can install MUI for the icon button
import { X } from "lucide-react";
import MDBox from "components/MDBox";

const ExistResult = ({ existResult, close }) => {
  const [isVisible, setIsVisible] = useState(true);
  console.log("existing result: " + JSON.stringify(existResult));

  const handleClose = () => {
    setIsVisible(false);
    close();
  };

  return (
    isVisible && (
      <MDBox
        pl={3}
        pt={3}
        style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Already Exist Results</span>
          <IconButton onClick={handleClose}>
            <X color="red" />
          </IconButton>
        </div>
        {existResult && existResult.length > 0 ? (
          existResult.map((student) => (
            <div key={student.student_id}>
              <p className="text-[14px]">
                {student.first_name} {student.middle_name} {student.last_name}: {student.mark}
              </p>
              <Divider />
            </div>
          ))
        ) : (
          <p>No Results</p>
        )}
      </MDBox>
    )
  );
};

ExistResult.propTypes = {
  existResult: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};

export default ExistResult;
