import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getExamType } from "../../utils/requests/exam/examrequest";
import Exams from "./Addexam";
import { LoadinSpiner } from "components/LoadingSpinner";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Button, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateExam } from "utils/requests/exam/createexam";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { deleteExam } from "utils/requests/exam/createexam";
import ConfirmationDialog from "helper/comfirmationOfDelete";
import EditableTable from "helper/editableTable";
import { Refresh } from "@mui/icons-material";

// const TableData = ({ refresh }) => {
//   const [validationErrors, setValidationErrors] = useState({});
//   const [rows, setRows] = useState([]);
//   const [is_deleted, setIsDeleted] = useState(false);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await getExamType();
//         setRows(response.data || []);
//       } catch (err) {
//         setError(err);
//       }
//     };

//     fetchData();
//   }, [refresh]);

//   const columnsDefinition = useMemo(
//     () => [
//       {
//         accessorKey: "exam_name",
//         header: "Exam Name",
//         muiEditTextFieldProps: {
//           required: true,
//           error: !!validationErrors?.exam_name,
//           helperText: validationErrors?.exam_name,
//           onFocus: () => setValidationErrors((prev) => ({ ...prev, exam_name: undefined })),
//         },
//       },
//       {
//         accessorKey: "max_result",
//         header: "Exam Result",
//         muiEditTextFieldProps: {
//           required: true,
//           error: !!validationErrors?.max_result,
//           helperText: validationErrors?.max_result,
//           onFocus: () => setValidationErrors((prev) => ({ ...prev, max_result: undefined })),
//         },
//       },
//       {
//         accessorKey: "description",
//         header: "Description",
//         muiEditTextFieldProps: {
//           required: true,
//           error: !!validationErrors?.description,
//           helperText: validationErrors?.description,
//           onFocus: () => setValidationErrors((prev) => ({ ...prev, description: undefined })),
//         },
//         size: 550,
//       },
//     ],
//     [validationErrors]
//   );

//   const handleSaveExamType = async (updatedRow) => {
//     const { values, table, row } = updatedRow;
//     const newValidationErrors = validateExamType(values);
//     if (Object.values(newValidationErrors).some((error) => error)) {
//       setValidationErrors(newValidationErrors);
//       return;
//     }

//     setValidationErrors({});
//     const exam_id = row.original?.exam_id;

//     if (exam_id) {
//       try {
//         const response = await updateExam(exam_id, values); // Make sure updateExam is an async function
//         if (response.success) {
//           snackbar("success", response.message);
//           table.setEditingRow(null);
//         } else {
//           snackbar("error", response.message);
//         }
//       } catch (error) {
//         snackbar("error", "Failed to update exam ");
//       }
//     }
//   };

//   const openDeleteConfirmModal = async (row, table) => {
//     if (window.confirm("Are you sure you want to delete this exam type it affects results?")) {
//       const exam_id = row.original?.exam_id;
//       if (exam_id) {
//         try {
//           const response = await deleteExam(exam_id);
//           if (response.success) {
//             snackbar("success", response.message);
//             setIsDeleted(!is_deleted);
//           } else {
//             snackbar("error", response.message);
//           }
//         } catch (error) {
//           snackbar("error", "Failed to delete exam ");
//         }
//       }
//     }
//   };

//   const table = useMaterialReactTable({
//     columns: columnsDefinition,
//     data: rows,
//     createDisplayMode: "row",
//     editDisplayMode: "row",
//     enableEditing: true,
//     displayColumnDefOptions: {
//       "mrt-row-actions": {
//         size: 150,
//         muiTableHeadCellProps: {
//           align: "center",
//         },
//       },
//     },
//     enableColumnResizing: true,
//     onEditingRowCancel: () => setValidationErrors({}),
//     onEditingRowSave: handleSaveExamType,
//     getRowId: (row) => row.id,
//     enableRowActions: true,
//     renderRowActions: ({ row, table }) => (
//       <Box sx={{ display: "flex", gap: "1rem" }}>
//         <Tooltip title="Edit">
//           <IconButton onClick={() => table.setEditingRow(row)}>
//             <EditIcon />
//           </IconButton>
//         </Tooltip>
//         <Tooltip title="Delete">
//           <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       </Box>
//     ),
//   });

//   return <MaterialReactTable table={table} />;
// };

// TableData.propTypes = {
//   refresh: PropTypes.bool.isRequired,
// };

// Function to validate required fields
const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

// Function to validate user inputs
const validateExamType = (data) => {
  return {
    exam_name: !validateRequired(data.exam_name) ? "Exam Name is required" : "",
    max_result: !validateRequired(data.max_result) ? "Max Result is required" : "",
    description: !validateRequired(data.description) ? "Description is required" : "",
  };
};

export default function Examtypes() {
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [is_refresh, setIsRefresh] = useState(false);

  const columnsDefinition = useMemo(
    () => [
      {
        accessorKey: "exam_name",
        header: "Exam Name",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.exam_name,
          helperText: validationErrors?.exam_name,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, exam_name: undefined })),
        },
      },
      {
        accessorKey: "max_result",
        header: "Exam Result",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.max_result,
          helperText: validationErrors?.max_result,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, max_result: undefined })),
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, description: undefined })),
        },
        size: 550,
      },
    ],
    [validationErrors]
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExamType();
        setRows(response.data || []);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, [is_refresh]);

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validateExamType(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    const exam_id = row.original?.exam_id;

    if (exam_id) {
      try {
        const response = await updateExam(exam_id, values);

        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to update exam type");
      }
    }
  };

  const handleDelete = async () => {
    const exam_id = deleteRow?.original?.exam_id;
    if (exam_id) {
      try {
        const response = await deleteExam(exam_id);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to delete exam type");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Exams
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => setAddVisibility(!addVisibility)}
                  >
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>
            {addVisibility ? (
              <Exams onExamLoad={() => setIsRefresh(!is_refresh)} />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : error ? (
                  <MDTypography color="error">
                    Something went wrong. Please try again later.
                  </MDTypography>
                ) : (
                  <EditableTable
                    columns={columnsDefinition}
                    data={rows}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                  />
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete exam type"
        message="Are you sure you want to delete this exam type? This action will affect associated data."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
}
