import axiosInstance from "config/axios-config.js";

export const createSchoolPlan = async (data) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.post("/schoolPlan", data);
    // // console.log("response from create school plan", JSON.stringify(res, null, 2));
    if (res && res.data && res.data.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const updateSchool = async ({ school_id, school_plan_id }) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.put(`/school/${school_id}`, { school_plan_id });
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to update school plan.";
  }
  return response;
};

export const getSchoolDetail = async (school_id) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.get(`/school/${school_id}`);
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to fetch school details.";
  }
  return response;
  s;
};

export const updatedSchoolPlan = async ({ school_plan_id, data }) => {
  console.log("data", data);
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.put(`/schoolPlan/${school_plan_id}`, data);
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to fetch updated school plan.";
  }
  return response;
};

export const deleteSchoolPlan = async (school_plan_id) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/schoolPlan/${school_plan_id}`);
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to delete school plan.";
  }
  return response;
};
