import axiosInstance from "config/axios-config.js";

export const createSchedule = async (data) => {
  try {
    const response = await axiosInstance.post("/programGenerator", data);
    // console.log("response: " + JSON.stringify(response, null, 2));
    sendScheduleResponse(response);
    // Assuming a successful response will have a status code in the 200 range
    if (response.status >= 200 && response.status < 300) {
      return { success: true, data: response.data };
    } else {
      return {
        success: false,
        error: response.message,
      };
    }
  } catch (error) {
    // console.log("error", error);
    return {
      success: false,
      error: error.response?.data?.message || "Can't create schedule program",
    };
  }
};

export const sendScheduleResponse = (response) => {
  if (response.success) {
    // Send success response
    return {
      success: true,
      data: response.data,
    };
  } else {
    // Send failure response
    return {
      success: false,
      data: response.data,
    };
  }
};

export const updateProgram = async (data) => {
  const response = {
    data: null,
    success: true,
    message: "",
  };
  try {
    const res = await axiosInstance.put("/programGenerator", data);
    if (res.data.success) {
      response.data = res.data.data;
      response.message = res.data.message;
      response.success = res.data.success;
    }
  } catch (error) {
    response.success = false;
    response.message = error.response?.data?.message || "Can't update program";
  }
  return response;
};
export const deleteProgram = async (data) => {
  const response = {
    data: null,
    success: true,
    message: "",
  };
  try {
    console.log(data);
    const res = await axiosInstance.delete(`/programGenerator`, {
      params: {
        grade: data.grade,
        section: data.section,
        day: data.day,
      },
    });
    if (res.data.success) {
      response.data = res.data.data;
      response.message = res.data.message;
      response.success = res.data.success;
    }
  } catch (error) {
    response.success = false;
    response.message = error.response?.data?.message || "Can't delete program";
  }
  return response;
};
