import axiosInstance from "config/axios-config.js";

export const createSetting = async (data) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.post("/setting", data);
    // console.log("response from create school plan", JSON.stringify(res, null, 2));
    if (res && res.data && res.data.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const updateSetting = async (setting_id, data) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.put(`/setting/${setting_id}`, { value: data });
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to update setting.";
  }
  return response;
};
