import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getExamType } from "utils/requests/exam/examrequest";
import { getStudentsByClass } from "utils/requests/students/studentsget";
import { createRequest } from "utils/requests/request/createRequest";

function RequestForm() {
  const [requestType, setRequestType] = useState("");
  const [message, setMessage] = useState("");
  const [studentsList, setStudentsList] = useState([]);
  const [regradeRequests, setRegradeRequests] = useState([
    { studentId: "", examType: "", mark: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [examTypeList, setExamTypeList] = useState([]);
  const messageInputRef = useRef(null);

  useEffect(() => {
    const fetchExamTypes = async () => {
      try {
        const response = await getExamType();
        setExamTypeList(response.data);
      } catch (error) {
        console.error("Error fetching exam types:", error);
        setExamTypeList([]);
      }
    };
    fetchExamTypes();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await getStudentsByClass();
        setStudentsList(response.data || []);
      } catch (error) {
        console.error("Error fetching students:", error);
        setStudentsList([]);
      }
    };
    fetchStudents();
  }, []);

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  const handleAddRegradeRequest = () => {
    setRegradeRequests([...regradeRequests, { studentId: "", examType: "", mark: "" }]);
  };

  const handleRemoveRegradeRequest = (index) => {
    setRegradeRequests(regradeRequests.filter((_, i) => i !== index));
  };

  const handleRegradeInputChange = (index, field, value) => {
    const updatedRequests = [...regradeRequests];
    updatedRequests[index][field] = value;
    setRegradeRequests(updatedRequests);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!requestType || !message) {
        await snackbar("error", "Message and request type are required.");
        setLoading(false);
        return;
      }

      if (requestType === "regrade") {
        for (const request of regradeRequests) {
          const { studentId, examType, mark } = request;
          if (!studentId || !examType || !mark) {
            await snackbar("error", "All fields are required for each regrade request.");
            setLoading(false);
            return;
          }
        }
      }

      let formData = {
        message: message,
        type: requestType,
        result_id: null,
        exams: requestType === "regrade" ? regradeRequests : null,
      };

      const response = await createRequest(formData);

      if (response.success) {
        await snackbar("success", response.message);
      } else {
        await snackbar("error", response.message);
      }
    } catch (error) {
      await snackbar("error", "An error occurred. The request was not submitted.");
    }

    setRequestType("");
    setMessage("");
    setRegradeRequests([{ studentId: "", examType: "", mark: "" }]);
    setLoading(false);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <MDBox margin={2}>
      <Card sx={{ height: "auto", width: "100%" }}>
        <CardContent>
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <MDTypography variant="h6" fontWeight="medium">
              Request Form
            </MDTypography>
          </MDBox>

          <MDBox mb={2} px={2}>
            <FormControl style={{ width: "45%" }} variant="outlined" margin="normal">
              <InputLabel id="request-type-label">Request Type</InputLabel>
              <Select
                labelId="request-type-label"
                id="request-type-select"
                value={requestType}
                label="Request Type"
                onChange={(e) => setRequestType(e.target.value)}
                sx={{ height: "40px", display: "flex", alignItems: "center" }}
                required
              >
                <MenuItem value="regrade">Regrade</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </MDBox>

          {requestType === "regrade" &&
            regradeRequests.map((request, index) => (
              <MDBox key={index} display="flex" gap={2} mb={2} px={2} alignItems="center">
                <FormControl style={{ width: "30%" }} variant="outlined" margin="normal">
                  <InputLabel id="student-label">Student</InputLabel>
                  <Select
                    labelId="student-label"
                    id="student-select"
                    value={request.studentId}
                    onChange={(e) => handleRegradeInputChange(index, "studentId", e.target.value)}
                    label="Student"
                    sx={{ height: "40px", display: "flex", alignItems: "center" }}
                  >
                    {studentsList.length > 0 ? (
                      studentsList.map((student) => (
                        <MenuItem key={student.student_id} value={student.student_id}>
                          {student.first_name} {student.middle_name} {student.last_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No students available</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl style={{ width: "30%" }} variant="outlined" margin="normal">
                  <InputLabel id="exam-type-label">Exam Type</InputLabel>
                  <Select
                    labelId="exam-type-label"
                    id="exam-type-select"
                    value={request.examType}
                    onChange={(e) => handleRegradeInputChange(index, "examType", e.target.value)}
                    label="Exam Type"
                    sx={{ height: "40px", display: "flex", alignItems: "center" }}
                  >
                    {examTypeList.length > 0 ? (
                      examTypeList.map((exam) => (
                        <MenuItem key={exam.exam_id} value={exam.exam_id}>
                          {exam.exam_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No exam types available</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <TextField
                  id="mark_input"
                  type="number"
                  label="Mark"
                  value={request.mark}
                  onChange={(e) => handleRegradeInputChange(index, "mark", e.target.value)}
                  variant="outlined"
                  margin="normal"
                  sx={{
                    width: "30%",
                    boxSizing: "border-box",
                    display: "flex",
                    "& .MuiInputBase-root": {
                      padding: "0px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "4px",
                    },
                    "& .MuiInputLabel-root": {
                      top: "0px",
                    },
                  }}
                  required
                />

                {index === regradeRequests.length - 1 && (
                  <IconButton
                    onClick={handleAddRegradeRequest}
                    color="primary"
                    style={{ marginTop: "10px" }}
                  >
                    <AddIcon />
                  </IconButton>
                )}

                {regradeRequests.length > 1 && index !== regradeRequests.length - 1 && (
                  <IconButton
                    onClick={() => handleRemoveRegradeRequest(index)}
                    color="secondary"
                    style={{ marginTop: "10px" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </MDBox>
            ))}

          <MDBox mb={2} px={2}>
            <TextField
              label="Reason"
              multiline
              rows={4}
              placeholder="Enter your reason here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              inputRef={messageInputRef}
              sx={{ width: "45%" }}
              required
            />
          </MDBox>

          <MDBox display="flex" justifyContent="flex-start" px={2} mt={3}>
            <MDButton
              color="info"
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Submitting..." : "Submit"}
            </MDButton>
          </MDBox>
        </CardContent>
      </Card>
    </MDBox>
  );
}

export default RequestForm;
