import {
  Watch,
  Audio,
  TailSpin,
  Bars,
  Grid,
  Circles,
  Hearts,
  Oval,
  Puff,
  Rings,
  ThreeDots,
} from "react-loader-spinner";
export const LoadinSpiner = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <ThreeDots // Type of spinner
        height="80"
        width="80"
        color="blue"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
