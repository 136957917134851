import { MaterialReactTable } from "material-react-table";
import PropTypes from "prop-types";
const ResultTable = ({ columns, rows }) => {
  return <MaterialReactTable columns={columns} data={rows} enableColumnResizing enableRowNumbers />;
};

ResultTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      size: PropTypes.number,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResultTable;
