import axiosInstance from "config/axios-config.js";

export const postTeachers = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    response = await axiosInstance.post("/staff", {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      email: data.email,
      subject_id: data.subject_id,
      educational_level: data.educational_level,
      role: data.role,
      grade_section: data.grade_section,
      grade_level: data.grade_level,
    });
    if (response.data.success) {
      response = {
        success: true,
        message: "Staff profile successfully created!",
        data: response.data.data,
      };
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const updateTeacher = async (data) => {
  console.log("Data fetched", data);
  let response = {
    success: false,
    message: "",
    data: null,
  };
  // console.log("data fetched from backed", data);
  try {
    const apiResponse = await axiosInstance.put(`/staff/${data.staff_id}`, {
      first_name: data.firstname,
      last_name: data.lastname,
      phone_number: data.phone,
      email: data.email,
      subject_id: data.subject !== "" ? data.subject : null,
      educational_level: data.educationallevel,
      role: data.role,
      grade_section: data.grade_section,
      grade_level: data.grade_level,
    });

    // Check the success status from API response
    if (apiResponse.data.success) {
      response = {
        success: true,
        message: "Staff profile successfully updated!",
        data: apiResponse.data.data,
      };
    } else {
      response.message = apiResponse.data.message || "Failed to update staff profile.";
    }
  } catch (error) {
    // Handle errors gracefully, providing a fallback message
    response.message =
      error.response?.data?.message || "An error occurred while updating the staff profile.";
  }

  return response;
};
