import { Avatar, Typography, Checkbox } from "@mui/material";
import AvatarColor from "components/ColorAvater/ColorAvater";
import MDBox from "components/MDBox";
import TextField from "@mui/material/TextField";
import React from "react";
import PropTypes from "prop-types";

export default function StudentField({
  student,
  index,
  sizes,
  handleMarkChange,
  marks,
  selectedStudents,
  handleSelect,
}) {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let targetId;
      if (index < sizes - 1) {
        targetId = index + 1;
      } else {
        targetId = index;
      }
      const nextField = document.getElementById(targetId);
      if (nextField) nextField.focus();
    }
  };

  const mark = marks[index] || "";

  return (
    <MDBox
      display="flex"
      alignItems="center"
      p={2}
      paddingLeft="0px"
      bgcolor="#f5f5f5"
      borderRadius="1px"
      boxShadow={1}
    >
      {/* <Checkbox
        checked={selectedStudents.includes(student.student_id)} // Check if student is selected
        onChange={() => handleSelect(student.student_id)}
      /> */}
      <Avatar sx={{ bgcolor: AvatarColor(student.first_name), marginRight: 2 }}>
        {student.first_name.charAt(0)}
      </Avatar>
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body1" color="textPrimary" sx={{ fontSize: "0.9rem" }}>
          {student.first_name} {student.middle_name}
        </Typography>
        <Typography variant="caption">
          {student.class?.grade_level} {student.class?.section}
        </Typography>
      </div>
      <TextField
        id={student.student_id} // Use unique ID per student
        label="Grade"
        type="number"
        value={mark}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        onKeyDown={handleEnterPress}
        onChange={(e) => handleMarkChange(student.student_id, e.target.value)} // Update with student ID
      />
    </MDBox>
  );
}

StudentField.propTypes = {
  student: PropTypes.shape({
    student_id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    middle_name: PropTypes.string.isRequired,
    class: PropTypes.shape({
      grade_level: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  sizes: PropTypes.number.isRequired,
  handleMarkChange: PropTypes.func.isRequired,
  selectedStudents: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  marks: PropTypes.array,
};
