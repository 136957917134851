import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Tables from "../../components/tables/table";
import { getAllClassResults } from "utils/requests/result/getResult";
import imageUrl from "../../assets/images/Gold Elegant Appreciation Certificate signature.png";
import jsPDF from "jspdf";
import { profile } from "context/profile";
import html2canvas from "html2canvas";
import Button from "@mui/material/Button";
import { Download } from "@mui/icons-material";
import { LoadinSpiner } from "components/LoadingSpinner";
import moment from "moment";
import axios from "axios";
import MDTypography from "components/MDTypography";
import "jspdf-autotable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { getSchoolDetail } from "utils/requests/schoolPlan/createSchool";
import { snackbar } from "components/awesome_snackbar/snackbar";

export default function Roaster() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [schoolData, setSchoolData] = useState([]);
  const [filterType, setFilterType] = useState("rank");
  const [filterValue, setFilterValue] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resultsResponse = await getAllClassResults();
        // console.log("result returned", JSON.stringify(resultsResponse, null, 2));
        if (resultsResponse.success) {
          const subjects = Array.from(
            new Set(resultsResponse.data.map((result) => result?.subject?.name).filter(Boolean))
          );

          const dynamicColumns = [
            { accessorKey: "studentName", header: "Student Name" },
            { accessorKey: "grade", header: "Grade" },
            { accessorKey: "section", header: "Section" },
            { accessorKey: "gender", header: "Gender" },
            { accessorKey: "age", header: "Age" },
            ...subjects.map((subject) => ({
              accessorKey: subject.charAt(0).toUpperCase() + subject.slice(1),
              header: subject.charAt(0).toUpperCase() + subject.slice(1),
              size: 100,
            })),
            { accessorKey: "total", header: "Total" },
            { accessorKey: "average", header: "Average" },
            { accessorKey: "ranking", header: "Ranking" },
            { accessorKey: "semester", header: "Semester" },
          ];

          setColumns(dynamicColumns);

          const studentResultsMap = {};
          resultsResponse.data.forEach((result) => {
            const studentId = result.student.student_id;
            const studentName = `${result.student.first_name} ${result.student.last_name}`;
            const grade = `${result?.student?.class?.grade_level}`;
            const section = `${result.student?.class.section}`;
            const gender = result.student?.gender.toUpperCase();
            const birthDate = result.student?.birth_date;
            const age = birthDate ? moment().diff(moment(birthDate), "years") : null;

            if (!studentResultsMap[studentId]) {
              studentResultsMap[studentId] = {
                studentName,
                grade,
                section,
                gender,
                age,
                total: 0,
                average: 0,
                ranking: 0,
                semester: result.semester,
                ...subjects.reduce((acc, subject) => {
                  acc[subject.charAt(0).toUpperCase() + subject.slice(1)] = 0;
                  return acc;
                }, {}),
              };
            }

            const subjectName = result.subject?.name
              ? result.subject.name.charAt(0).toUpperCase() + result.subject.name.slice(1)
              : "Unknown";

            studentResultsMap[studentId][subjectName] += result.mark;
            studentResultsMap[studentId].total += result.mark;
          });

          Object.values(studentResultsMap).forEach((student) => {
            student.average = student.total / subjects.length;
          });

          const sortedStudents = Object.values(studentResultsMap).sort((a, b) => b.total - a.total);
          sortedStudents.forEach((student, index) => {
            student.ranking = index + 1;
          });

          setRows(sortedStudents);
        } else {
          setError(resultsResponse.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSchool = async () => {
      const res = await getSchoolDetail(profile.school_id);
      console.log("response of school data", JSON.stringify(res, null, 2));
      if (res.success) {
        setSchoolData(res.data);
      }
    };
    fetchSchool();
  }, []);

  const handleDownload = (fileType) => {
    if (rows.length === 0) return;
    if (fileType === "roaster") {
      generatePDF();
    } else if (fileType === "certificate") {
      downloadCertificate();
      setOpenFilter(false);
    }

    setOpenDialog(false);
  };

  const generatePDF = async () => {
    const doc = new jsPDF("portrait", "pt", "a4");

    rows.forEach((row, index) => {
      if (index > 0) {
        doc.addPage();
      }

      doc.setFontSize(24);
      doc.text("School Report Card", 150, 50);

      doc.setFontSize(18);
      doc.text(`School Name: ${profile.school_name}`, 200, 80);
      doc.setFontSize(14);
      doc.text(`Student Name: ${row.studentName}`, 40, 110);
      doc.text(`Age: ${row.age}`, 40, 130);
      doc.text(`Sex: ${row.gender.toUpperCase()}`, 350, 130);
      doc.text(`Class: ${row.section}`, 350, 110);
      doc.setFontSize(14);
      doc.text(`Semester: ${row.semester}`, 40, 150);

      const subjectResults = Object.keys(row).filter(
        (key) =>
          ![
            "total",
            "average",
            "ranking",
            "studentName",
            "section",
            "gender",
            "age",
            "semester",
          ].includes(key) // Exclude semester from subjects
      );

      const tableData = [
        ["Subject", "Marks"], // Header for subjects and marks
        ...subjectResults.map((subject) => [
          subject,
          typeof row[subject] === "number" ? row[subject].toFixed(2) : "-", // Display each subject's marks
        ]),
        [
          "Total",
          typeof row.total === "number" ? row.total.toFixed(2) : "-", // Total marks
        ],
        [
          "Average",
          typeof row.average === "number" ? row.average.toFixed(2) : "-", // Average marks
        ],
        ["Rank", row.ranking], // Display ranking
      ];

      doc.autoTable({
        startY: 190, // Adjust the start position as needed
        head: tableData.slice(0, 1),
        body: tableData.slice(1),
        styles: {
          halign: "center",
        },
      });

      doc.text("Home room teacher", 40, 710);
      doc.text(`name: ${profile.first_name} ${profile.last_name}`, 40, 730);
      doc.text(`signature:_____________________`, 40, 760);
      doc.text("School Dean", 350, 710);
      doc.text(`name:`, 350, 730);
      doc.text(`signature:_____________________`, 350, 760);
    });

    doc.save("report_card.pdf");
  };

  const downloadCertificate = async () => {
    const img = new Image();
    const today = new Date();
    const dateStr = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    img.src = imageUrl;

    img.onload = function () {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [840, 600],
      });
      //filter atudens by value and type of filter

      const filteredRows = rows.filter((student) =>
        filterType === "rank"
          ? student.ranking <= parseInt(filterValue)
          : student.average >= parseFloat(filterValue)
      );
      if (filteredRows.length === 0) {
        snackbar("error", "No students match the filter criteria.");
        setFilterValue("");
        return;
      }

      filteredRows.forEach((student, index) => {
        if (index > 0) {
          doc.addPage();
        }

        doc.addImage(img, "PNG", 0, 0, 840, 600);

        doc.setFontSize(24);
        doc.setTextColor(162, 133, 53);
        doc.text(`${student.studentName}`, 420, 280, { align: "center" });
        doc.text(`${schoolData.name}`, 428, 218, { align: "center" });

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(`${student.grade}`, 427, 335, { align: "center" });
        doc.text(`${student.section}`, 550, 335, { align: "center" });

        doc.text(`${student.average.toFixed(2)}`, 350, 380, { align: "center" });
        doc.text(`${student.ranking}`, 570, 380, { align: "center" });

        doc.text(`Date: ${dateStr}`, 700, 570, { align: "right" });
      });

      doc.save("students_certificates.pdf");
      setFilterValue("");
      setOpenFilter(!openFilter);
    };

    img.onerror = function () {
      console.error("Failed to load the image.");
    };
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Roaster
              </MDTypography>
              <Button
                variant="contained"
                color="info"
                startIcon={<Download />}
                onClick={() => setOpenDialog(true)}
                style={{
                  marginLeft: "10px",
                  color: "blue",
                }}
                disabled={rows.length === 0} // Disable the button if there are no rows
              >
                Download
              </Button>
            </MDBox>

            <MDBox mt={2} ml={2} mr={2}>
              {loading ? (
                <LoadinSpiner />
              ) : error ? (
                <div className="text-gray-600 font-normal text-center capitalize text-[13px]">
                  {error}
                </div>
              ) : (
                <Tables columns={columns} rows={rows} />
              )}
            </MDBox>
          </Grid>
        </Grid>
        <Dialog open={openFilter} onClose={() => setOpenFilter(false)}>
          <DialogTitle>Select File Type and Filter</DialogTitle>
          <DialogContent>
            <RadioGroup value={filterType} onChange={handleFilterChange}>
              <FormControlLabel value="rank" control={<Radio />} label="Filter by Rank" />
              <FormControlLabel value="average" control={<Radio />} label="Filter by Average" />
            </RadioGroup>
            <TextField
              label={filterType === "rank" ? "Enter Rank Limit" : "Enter Minimum Average"}
              type="number"
              value={filterValue}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0 || value === "") {
                  setFilterValue(value);
                }
              }}
              fullWidth
            />

            <div className="flex justify-between items-center">
              <Button onClick={() => handleDownload("certificate")} color="primary">
                Download
              </Button>
              <Button onClick={() => setOpenFilter(!openFilter)} color="primary">
                cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Select File Type to Download</DialogTitle>
          <DialogActions>
            <Button onClick={() => handleDownload("roaster")} color="primary">
              Download Roaster
            </Button>
            <Button
              onClick={() => {
                setOpenFilter(true);
                setOpenDialog(!openDialog);
              }}
              color="primary"
            >
              Download Certificate
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}
