import axiosInstance from "config/axios-config.js";

export const creatSubject = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    response = await axiosInstance.post("/subject", {
      name: data.name,
      description: data.description,
      grade_start: data.grade_start,
      grade_end: data.grade_end,
    });
    if (response.data.success) {
      response = {
        success: true,
        message: "Staff profile successfully created!",
        data: response.data.data,
      };
    } else {
      response = {
        success: false,
        message: response.data.message,
        data: null,
      };
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const updateSubject = async (subject_id, data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.put(`/subject/${subject_id}`, data);

    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message || "subject updated successfully.";
      response.data = res.data.data;
    } else {
      response.message = res.data.message || "Failed to update subject.";
      response.success = false;
      response.data = null;
    }
  } catch (error) {
    response.message =
      error.response.data.message || "An error occurred while updating the subject.";
    response.success = false;
    response.data = null;
  }

  return response;
};
export const deleteSubject = async (subject_id) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/subject/${subject_id}`);
    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message || "subject deleted successfully.";
      response.data = null;
    } else {
      response.message = res.data.message || "Failed to delete subject.";
      response.success = false;
      response.data = null;
    }
  } catch (error) {
    response.message =
      error.response.data.message || "An error occurred while deleting the subject.";
    response.success = false;
    response.data = null;
  }
  return response;
};
