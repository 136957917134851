import axiosInstance from "config/axios-config.js";

export const createClass = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.post("/class", {
      building_name: data.building_name,
      capacity: data.capacity,
      section_start: data.section_start,
      section_end: data.section_end,
      grade_level: data.grade_level,
    });

    if (res.data.success) {
      response = {
        success: true,
        message: "Classes were successfully created!",
        data: res.data.data,
      };
    } else {
      response.message = "Failed to create classes.";
    }
  } catch (error) {
    response.message = error.response?.data?.message || "An unexpected error occurred.";
  }

  return response;
};

export const updateClass = async ({ class_id, data }) => {
  console.log("data from frontend", data);
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.put(`/class/${class_id}`, {
      building_name: data.building_name,
      capacity: data.capacity,
      section: data.section,
      grade_level: data.grade_level,
    });
    if (res.data.success) {
      response = {
        success: true,
        message: "Class was successfully updated!",
        data: res.data.data,
      };
    } else {
      response.message = "Failed to update class.";
    }
  } catch (error) {
    response.message = error.response?.data?.message || "An unexpected error occurred.";
  }
  return response;
};

export const deleteClass = async (class_id) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/class/${class_id}`);
    if (res && res.data) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to delete class.";
  }
  return response;
};
