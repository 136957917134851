import axiosInstance from "config/axios-config";
import { axiosInstanceAuth } from "config/axios-config";
import { profile } from "context/profile";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadinSpiner } from "components/LoadingSpinner";
export const BroadcastMessage = async (braodcastJson) => {
  let response;
  let axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    response = await axiosAuth
      .post("/message", braodcastJson)
      .then((res) => {
        if (res.data.success) return true;
        else return false;
      })
      .catch((error) => {
        response = error.response.data.success;
        return false;
      });
  } catch (error) {
    return false;
  }
  return response;
};
