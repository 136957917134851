import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ScheduleForm from "./scheduleForm";
import ScheduleTable from "./scheduleTable";

const ClassSchedule = () => {
  const [addVisibility, setAddVisibility] = useState(false);

  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Schedules
              </MDTypography>

              <MDBox display="flex" alignItems="center">
                <Avatar
                  style={{
                    backgroundColor: "white",
                    padding: 8,
                    cursor: "pointer",
                  }}
                >
                  <IconButton style={{ padding: 0 }} onClick={toggleAddVisibility}>
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {/* Conditional Rendering of Schedule Form or Schedule Table */}
            {addVisibility ? (
              <ScheduleForm />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                <ScheduleTable />
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ClassSchedule;
