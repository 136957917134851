import { axiosInstanceAuth } from "config/axios-config";
import { axiosInstance } from "config/axios-config";
import { profileDataMapper } from "../authentication";
import { setToken } from "config/storageController";
import { snackbar } from "components/SnackBar";

export const resetPassword = async (oldPassword, newPassword) => {
  const response = {
    data: null,
    success: false,
    message: "",
  };
  try {
    let token_reseter = localStorage.getItem("token");
    // console.log("access token: " + token_reseter);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token_reseter}`;
    const res = await axiosInstance.post("/account/reset", {
      oldPassword,
      newPassword,
    });
    if (res.data.success) {
      localStorage.clear();
      profileDataMapper(res.data.data);
      setToken(res.data.data.accessToken);
      snackbar(`${res.data.message}`, {
        theme: "light",
        position: "top-center",
        style: {
          container: [
            ["background-color", "green"],
            ["color", "white"],
          ],
        },
      });
      response.message = res.data.message;
      response.success = res.data.success;
      return response;
    } else {
      response.message = res.data.message;
      response.success = res.data.success;

      return response;
    }
  } catch (error) {
    response.message = error.response.data.message;
    return response;
  }
};

export const verifyOtp = async (phone, otp) => {
  let res = {
    data: null,
    success: false,
    message: "No reponse",
  };
  try {
    let axiosAuth = axiosInstanceAuth().axiosInstance;
    const response = await axiosAuth.post("/account/verify", {
      phone_number: phone,
      otp: otp,
    });
    // console.log(response);
    res = response.data;
    return res;
  } catch (err) {
    // console.log(err.response.data);
    res = err.response.data;
    return res;
  }
};
