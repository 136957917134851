import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";

export const Registration = (props) => {
  let [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    setIsVisible(props.isVisible);
  }, [props]);
  return (
    <Avatar style={{ backgroundColor: "white", color: "black", cursor: "pointer" }}>
      {isVisible ? <Icon>add</Icon> : <ArrowBack />}
    </Avatar>
  );
};
