import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { snackbar } from "components/awesome_snackbar/snackbar";
import CreateSchoolPlan from "./CreateShoolPlan";
import { LoadinSpiner } from "components/LoadingSpinner";
import { getSchoolPlan } from "utils/requests/schoolPlan/getSchoolPlan";
import { getSchool } from "utils/requests/schoolPlan/getSchoolPlan";
import EditableTable from "helper/editableTable";
import { deleteSchoolPlan } from "utils/requests/schoolPlan/createSchool";
import { updatedSchoolPlan } from "utils/requests/schoolPlan/createSchool";
import moment from "moment";
import ConfirmationDialog from "helper/comfirmationOfDelete";

// Helper function to format dates
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};
// Validation functions remain the same
const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

const validateschoolPlan = (data) => {
  return {
    semester: !validateRequired(data.semester) ? "Semester is required" : "",
    start_date: !validateRequired(data.start_date) ? "Start Date is required" : "",
    end_date: !validateRequired(data.end_date) ? "End Date is required" : "",
  };
};

export default function SchoolPlanPage() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [addVisibility, setAddVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [schoolData, setSchoolData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [is_refresh, setIsRefresh] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  useEffect(() => {
    fetchSchoolPlanData();
  }, [is_refresh]);

  const fetchSchoolPlanData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const [schoolPlanResponse, schoolResponse] = await Promise.all([
        getSchoolPlan(),
        getSchool(),
      ]);

      if (schoolPlanResponse.success && schoolResponse.success) {
        const schoolPlans = schoolPlanResponse.data;
        const schoolData = schoolResponse.data;
        setSchoolData(schoolData);

        setColumns([
          { accessorKey: "year", header: "Year", enableEditing: false },
          {
            accessorKey: "semester",
            header: "Semester",
            muiEditTextFieldProps: {
              required: true,
              error: !!validationErrors?.semester,
              helperText: validationErrors?.semester,
              // onFocus: () => setValidationErrors((prev) => ({ ...prev, semester: undefined })),
            },
          },
          {
            accessorKey: "start_date",
            header: "Start Date",
            muiEditTextFieldProps: {
              required: true,
              error: !!validationErrors?.start_date,
              helperText: validationErrors?.start_date,
              // onFocus: () => setValidationErrors((prev) => ({ ...prev, start_date: undefined })),
            },
          },
          {
            accessorKey: "end_date",
            header: "End Date",
            muiEditTextFieldProps: {
              required: true,
              error: !!validationErrors?.end_date,
              helperText: validationErrors?.end_date,
              // onFocus: () => setValidationErrors((prev) => ({ ...prev, end_date: undefined })),
            },
          },
        ]);

        const formattedRows = schoolPlans.map((plan) => ({
          school_plan_id: plan.school_plan_id,
          year: new Date(plan.semester_start_date).getFullYear(),
          semester: plan.semester,
          start_date: formatDate(plan.semester_start_date),
          end_date: formatDate(plan.semester_end_date),
        }));

        setRows(formattedRows);
      } else {
        setError(schoolPlanResponse.message);
      }
    } catch (err) {
      setError("something went wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  // Define onEdit function to handle editing
  const onEdit = async (updatedRow) => {
    const { values, table, row } = updatedRow;
    const newValidationErrors = validateschoolPlan(values);

    // Attempt to parse dates with the appropriate format
    const startDate = moment(values.start_date, "MMMM DD, YYYY", true);
    const endDate = moment(values.end_date, "MMMM DD, YYYY", true);

    if (!startDate.isValid()) {
      newValidationErrors.start_date =
        "Start date must be in a valid format (e.g., January 12, 2025).";
    }

    if (!endDate.isValid()) {
      newValidationErrors.end_date = "End date must be in a valid format (e.g., June 12, 2025).";
    } else {
      // Ensure end date is after start date
      if (!startDate.isBefore(endDate)) {
        newValidationErrors.end_date = "End date must be after the start date.";
      }
    }

    // If there are validation errors, set them and exit
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    const school_plan_id = row.original?.school_plan_id;

    if (school_plan_id) {
      try {
        // Format dates to 'YYYY-MM-DD' for consistent backend handling
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        const formattedEndDate = endDate.format("YYYY-MM-DD");

        const response = await updatedSchoolPlan({
          school_plan_id: school_plan_id,
          data: {
            semester: values.semester,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            year: values.year,
          },
        });

        if (response.success) {
          snackbar("success", "School plan updated successfully");
          setIsRefresh(!is_refresh);
          table.setEditingRow(null); // exit editing mode
        } else {
          snackbar("error", "Failed to update school plan");
        }
      } catch (error) {
        snackbar("error", "Failed to update school plan");
      }
    }
  };

  const handleDelete = async () => {
    const school_plan_id = deleteRow.original?.school_plan_id;
    if (school_plan_id) {
      try {
        const response = await deleteSchoolPlan(school_plan_id);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch (error) {
        snackbar("error", "Failed to delete exam ");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  // Define onDelete function to handle deletions
  // const onDelete = async (row) => {
  //   if (window.confirm("Are you sure you want to delete this exam type it affects results?")) {
  //     const school_plan_id = row.original?.school_plan_id;
  //     console.log("school plan data", row.original);
  //     if (school_plan_id) {
  //       try {
  //         const response = await deleteSchoolPlan(school_plan_id);
  //         console.log("respone from server,", JSON.stringify(response, null, 2));
  //         if (response.success) {
  //           snackbar("success", response.message);
  //           setIsDeleted(!is_deleted);
  //         } else {
  //           snackbar("error", response.message);
  //         }
  //       } catch (error) {
  //         snackbar("error", "Failed to delete exam ");
  //       }
  //     }
  //   }
  // };

  const handleOnClick = () => {
    setAddVisibility(!addVisibility);
  };

  const arrowBackHandler = () => {
    setAddVisibility(false);
    fetchSchoolPlanData(); // Refresh after closing form
  };

  const addNewPlan = () => {
    setAddVisibility(false);
    fetchSchoolPlanData(); // Refresh after adding new plan
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                School Plans
              </MDTypography>

              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={addVisibility ? arrowBackHandler : handleOnClick}
                  >
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {addVisibility ? (
              <CreateSchoolPlan onPlanAdded={addNewPlan} />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : error ? (
                  <p className="text-gray-600 font-normal text-center capitalize">{error}</p>
                ) : columns.length > 0 && rows.length > 0 ? (
                  <EditableTable
                    columns={columns}
                    data={rows}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                  />
                ) : (
                  <p>No Plan Found!</p>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete school plan"
        message="Are you sure you want to delete this school plan? This action will affect associated data."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
}
