import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDButton from "components/MDButton";
import { FileUpload } from "components/FileUpload";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import "react-day-picker/dist/style.css";
import { createStudents } from "../../utils/requests/students/studentsget";
import { SnackbarProvider } from "notistack";
import { postTeachers } from "utils/requests/teachers/teacherspost";
import { getSubject } from "utils/requests/subjects/subjectRequests";
import { LoadinSpiner } from "components/LoadingSpinner";
import { snackbar } from "../awesome_snackbar/snackbar";
import { getClassBySchool } from "utils/requests/class/getClass";
import { getGradeLevelSubject } from "utils/requests/subjects/subjectRequests";
import { Typography } from "@mui/material";
import { roles } from "helper/educationLevelData";
import { educationLevels } from "helper/educationLevelData";
const AddingComp = (props) => {
  const { isStaff, pathname } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const [studentData, setStudentData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    parentFirstName: "",
    parentLastName: "",
    gradeLevel: null,
    gradeSection: null,
    parentEmail: "",
    dateOfBirth: dayjs().format("YYYY-MM-DD"),
    gender: "",
  });
  const [dateValue, setDateValue] = useState(dayjs());
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [subjects, setSubjects] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [formDate, setFormDate] = useState({
    role: "",
    grade_section: null,
    grade_level: null,
    educational_level: "",
    subject_id: null,
    phone_number: "",
    email: "",
    last_name: "",
    first_name: "",
    teacherrole: "",
  });

  useEffect(() => {
    const fetchClassData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
      setErrors(result.errors || {});
    };
    fetchClassData();
  }, []);
  useEffect(() => {
    const fetchSubjectsByGradeLevel = async () => {
      const result = await getGradeLevelSubject();
      setSubjects(result.data);
      setErrors(result.errors || {});
    };
    pathname.toLowerCase() === "teachers" && handleSubjectFetch();
    pathname.toLowerCase() === "students" && fetchSubjectsByGradeLevel();
  }, []);

  const validateFields = () => {
    const newErrors = {};
    const sevenYearsAgo = dayjs().subtract(7, "years");
    if (!studentData.firstName) newErrors.firstName = "First name is required";
    if (!studentData.middleName) newErrors.middleName = "Middle name is required";
    if (!studentData.lastName) newErrors.lastName = "Last name is required";
    if (!studentData.parentFirstName) newErrors.parentFirstName = "Parent first name is required";
    if (!studentData.parentLastName) newErrors.parentLastName = "Parent last name is required";
    if (!studentData.gradeLevel) newErrors.gradeLevel = "Grade level is required";
    if (!studentData.gradeSection) newErrors.gradeSection = "Grade section is required";
    const phoneError = validatePhone(phone);
    if (phoneError) newErrors.phone = phoneError;
    if (dayjs(dateValue).isAfter(sevenYearsAgo)) {
      newErrors.dateOfBirth = "students year must be at least 7 years ago";
    }
    if (!studentData.gender) newErrors.gender = "Gender is required";
    // Parent email validation (optional)
    if (
      studentData.parentEmail &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(studentData.parentEmail)
    ) {
      newErrors.parentEmail = "Invalid parent email format";
    }

    setErrors(newErrors);
    return newErrors;
  };
  // Phone number validation
  const validatePhone = (phone) => {
    const phonePattern = /^(?:\+2519\d{8}|\+2517\d{8}|2519\d{8}|2517\d{8}|\d{10})$/;

    if (!phone) {
      return "Phone number is required";
    }

    if (!phonePattern.test(phone)) {
      return "Phone number must be 10 digits starting with 09 or 07, or 14 digits starting with +2519 or +2517";
    }

    return null; // Return null if no errors
  };
  const validateStaff = () => {
    const newErrors = {};
    if (!formDate.first_name) newErrors.first_name = "Staff first name is required";
    if (!formDate.last_name) newErrors.last_name = "Staff last name is required";
    if (!formDate.email) {
      newErrors.email = "Staff email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formDate.email)) {
      newErrors.email = "Invalid email format";
    }
    const phoneError = validatePhone(formDate.phone_number);
    if (phoneError) newErrors.phone_number = phoneError;

    if (!formDate.role) newErrors.role = "Staff role is required";
    setErrors(newErrors);

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleGradeLevelChange = (e) => {
    const selectedGrade = Number(e.target.value);
    if (pathname.toLowerCase() === "teachers") {
      setFormDate((prevData) => ({
        ...prevData,
        grade_level: selectedGrade,
        grade_section: "",
      }));
    } else if (pathname.toLowerCase() === "students") {
      setStudentData((prev) => ({
        ...prev,
        gradeLevel: selectedGrade,
        gradeSection: "",
      }));
    }

    const sectionsForGrade = classData
      .filter((cls) => cls.grade_level === selectedGrade)
      .map((cls) => cls.section);
    setFilteredSections(sectionsForGrade);
  };

  const handleSubmitStudent = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      await dataOrganization();
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateStaff();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      const data = {
        first_name: formDate.first_name,
        last_name: formDate.last_name,
        phone_number: formDate.phone_number,
        email: formDate.email || null,
        subject_id: formDate.subject_id || null,
        educational_level: formDate.educational_level,
        role: formDate.role,
        grade_level: formDate.grade_level || null,
        grade_section: formDate.grade_section || null,
      };

      const response = await postTeachers(data);
      console.log("response: " + JSON.stringify(response, null, 2));
      if (response.success) {
        snackbar("success", response.message);
        setFormDate({
          role: "",
          grade_section: null,
          grade_level: null,
          educational_level: "",
          subject_id: null,
          phone_number: "",
          email: "",
          last_name: "",
          first_name: "",
        });
      } else {
        snackbar("error", response.message);
      }
    }
  };
  const dataOrganization = async () => {
    try {
      const formData = {
        studentInfo: {
          first_name: studentData.firstName,
          middle_name: studentData.middleName,
          last_name: studentData.lastName,
          grade: studentData.gradeLevel,
          section: studentData.gradeSection,
          gender: document.querySelector('input[name="gender"]:checked').value || "m",
          birth_date: dateValue.format("YYYY-MM-DD") || "2002-01-01",
        },
        first_namep: studentData.parentFirstName || studentData.middleName,
        last_namep: studentData.parentLastName || studentData.lastName,
        phone_numberp: phone,
        emailp: studentData.parentEmail || "",
      };
      // // console.log("student ifo before sending to request", formData);
      const response = await createStudents(formData);
      console.log("response", JSON.stringify(response, null, 2));
      if (response.success) {
        snackbar("success", response.message);
        setStudentData({
          firstName: "",
          middleName: "",
          lastName: "",
          parentFirstName: "",
          parentLastName: "",
          gradeLevel: null,
          gradeSection: null,
          parentEmail: "",
          dateOfBirth: dayjs().format("YYYY-MM-DD"),
          gender: "",
        });
        setPhone("");
        setDateValue(dayjs());
      } else {
        snackbar("error", response.message);
      }
    } catch (error) {
      console.log("error was", error);
      snackbar("error", `can't create student `);
    }
  };
  const handleKeyPress = (event, nextFieldId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(nextFieldId)?.focus();
    }
  };
  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleSubjectFetch = async () => {
    setIsLoading(true);
    const response = await getSubject();
    setIsLoading(false);
    if (response.success) setSubjects(response.data);
  };

  const data = {
    subjects: subjects,
    classDatas: classData,
    roles: ["teacher", "encoder", "admin", "registrar"],
  };
  return isStaff ? (
    <MDBox display="flex" justifyContent="space-around" flexDirection={{ xs: "column", md: "row" }}>
      <MDBox pt={4} flex={1} mx={3}>
        <SnackbarProvider />
        <MDBox mb={2}>
          <MDInput
            id="first-name"
            label="First name"
            value={formDate.first_name}
            onChange={(e) => setFormDate({ ...formDate, first_name: e.target.value })}
            style={{ width: "45%" }}
            onKeyPress={(event) => handleKeyPress(event, "last-name")}
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
          <MDInput
            id="last-name"
            type="last-name"
            value={formDate.last_name}
            onChange={(e) => setFormDate({ ...formDate, last_name: e.target.value })}
            label="Last name"
            style={{ width: "45%", marginLeft: "5%" }}
            onKeyPress={(event) => handleKeyPress(event, "phone_input")}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
        </MDBox>
        <MDBox mb={2}>
          <PhoneInput
            id="phone_input"
            country={"et"}
            value={formDate.phone_number}
            onChange={(number) => setFormDate({ ...formDate, phone_number: number })}
            placeholder="+ (251) 985-042-445"
            preferredCountries={["et", "ke", "dj", "sd"]}
            enableSearch={true}
            countryCodeEditable={false}
            PhoneInputCountryFlag-borderColor={{ color: "brown" }}
            inputStyle={{
              width: "95%",
              height: "45px",
            }}
            autocompleteSearch={true}
            onKeyPress={(event) => handleKeyPress(event, "email")}
          />
          {errors.phone_number && (
            <div style={{ color: "red", marginTop: 5, fontSize: "14px" }}>
              {errors.phone_number}
            </div>
          )}
        </MDBox>
        <MDBox>
          <MDInput
            id="email"
            type="email"
            value={formDate.email}
            onChange={(e) => setFormDate({ ...formDate, email: e.target.value })}
            label="Email"
            style={{ width: "95%" }}
            onKeyPress={(event) => handleKeyPress(event, "subject")}
            error={!!errors.email}
            helperText={errors.email}
          />
        </MDBox>
        <select
          id="role"
          value={formDate.role}
          onChange={(e) => setFormDate({ ...formDate, role: e.target.value })}
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Role</option>
          {roles.map((role) => (
            <option key={role.value} value={role.value}>
              {role.label}
            </option>
          ))}
        </select>
        {formDate.role === "teacher" && (
          <MDBox mt={2} pl={2}>
            <Typography component="p" style={{ fontSize: "16px" }}>
              Teacher Role
            </Typography>
            <FormControl style={{ marginLeft: "0%" }}>
              <RadioGroup
                aria-label="teacherrole"
                row
                name="teacherrole"
                value={formDate.teacherrole}
                onChange={(e) => setFormDate({ ...formDate, teacherrole: e.target.value })}
              >
                <FormControlLabel
                  value="subjectTeacher"
                  control={<Radio />}
                  label="Subject Teacher"
                />
                <FormControlLabel
                  value="guidanceTeacher"
                  control={<Radio />}
                  label="Guidance Teacher"
                />
              </RadioGroup>
            </FormControl>
          </MDBox>
        )}

        <select
          id="educational_level"
          value={formDate.educational_level}
          onChange={(e) => setFormDate({ ...formDate, educational_level: e.target.value })}
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
          }}
        >
          <option value="">Educational Level</option>
          {educationLevels.map((level) => (
            <option key={level.value} value={level.value}>
              {level.label}
            </option>
          ))}
        </select>
        <select
          id="subject"
          value={formDate.subject_id}
          onChange={(e) => setFormDate({ ...formDate, subject_id: e.target.value })}
          style={{
            width: "95%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
            onKeyPress: (event) => handleKeyPress(event, "educational_level"),
          }}
          disabled={formDate.role === "registrar" || formDate.role === "encoder"}
        >
          <option value="">subject</option>
          {!isLoading ? (
            subjects.map((subject) => {
              return (
                <option key={subject.subject_id} value={subject.subject_id}>
                  {subject.name}
                </option>
              );
            })
          ) : (
            <LoadinSpiner />
          )}
        </select>
        <select
          id="grade_level"
          value={formDate.grade_level}
          onChange={handleGradeLevelChange}
          style={{
            width: "45%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 10,
          }}
          disabled={
            formDate.role === "registrar" ||
            formDate.role === "encoder" ||
            formDate.teacherrole === "" ||
            formDate.teacherrole === "subjectTeacher"
          }
        >
          <option value="">Grade </option>
          {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </select>
        <select
          id="grade_section"
          value={formDate.grade_section}
          onChange={(e) => setFormDate({ ...formDate, grade_section: e.target.value })}
          style={{
            width: "45%",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "5px",
            color: "grey",
            border: "1px solid lightgrey",
            marginTop: 20,
            marginLeft: "5%",
          }}
          disabled={
            filteredSections.length === 0 ||
            formDate.role === "registrar" ||
            formDate.role === "encoder"
          }
        >
          <option value="">Section *</option>
          {filteredSections.map((cls, index) => (
            <option key={index} value={cls}>
              {cls}
            </option>
          ))}
        </select>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: "10%",
          }}
        >
          <MDButton variant="gradient" color="info" style={{ width: "50%" }} onClick={handleSubmit}>
            Add Staff
          </MDButton>
        </div>
      </MDBox>
      <FileUpload isStaff={isStaff} datas={data} />
    </MDBox>
  ) : (
    pathname.toLowerCase() === "students" && (
      <MDBox
        display="flex"
        justifyContent="space-around"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <MDBox pt={4} flex={1} mx={3}>
          <MDBox mb={2}>
            <MDInput
              id="first-name"
              label="First name *"
              name="firstName"
              value={studentData.firstName}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "last-name")}
              style={{ width: "45%" }}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <MDInput
              id="middle-name"
              type="text"
              label="Middle name *"
              name="middleName"
              value={studentData.middleName}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "parent-first-name")}
              style={{ width: "45%", marginLeft: "5%" }}
              error={!!errors.middleName}
              helperText={errors.middleName}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              id="last-name"
              type="text"
              label="Last name *"
              name="lastName"
              value={studentData.lastName}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "parent-first-name")}
              style={{ width: "95%" }}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </MDBox>
          <MDBox>
            <MDInput
              id="parent-first-name"
              label="Parent First name *"
              name="parentFirstName"
              value={studentData.parentFirstName}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "parent-last-name")}
              style={{ width: "45%" }}
              error={!!errors.parentFirstName}
              helperText={errors.parentFirstName}
            />
            <MDInput
              id="parent-last-name"
              type="text"
              label="Parent Last name *"
              name="parentLastName"
              value={studentData.parentLastName}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "grade_level")}
              style={{ width: "45%", marginLeft: "5%" }}
              error={!!errors.parentLastName}
              helperText={errors.parentLastName}
            />
          </MDBox>
          <select
            id="grade_level"
            name="gradeLevel"
            value={studentData.gradeLevel}
            onChange={handleGradeLevelChange}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 10,
            }}
          >
            <option value="">Grade </option>
            {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </select>

          <select
            id="grade_section"
            value={studentData.gradeSection}
            name="gradeSection"
            onChange={handleChange}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 20,
              marginLeft: "5%",
            }}
            disabled={filteredSections.length === 0}
          >
            <option value="">Section *</option>
            {filteredSections.map((section, index) => (
              <option key={index} value={section}>
                {section}
              </option>
            ))}
          </select>
          {errors.gradeSection && errors.gradeLevel && (
            <div style={{ color: "red", marginTop: 3, fontSize: "13px" }}>
              {errors.gradeLevel} and {errors.gradeSection}
            </div>
          )}
          <MDBox mb={2} mt={2}>
            <PhoneInput
              id="phone_input"
              country={"et"}
              value={phone}
              placeholder="+ (251) 900-000-00"
              onChange={(numbers) => {
                setPhone(numbers);
              }}
              preferredCountries={["et", "ke", "dj", "sd"]}
              enableSearch={true}
              countryCodeEditable={false}
              inputStyle={{
                width: "95%",
                height: "45px",
              }}
              autocompleteSearch={true}
              onKeyPress={(event) => handleKeyPress(event, "parent-email")}
            />
            {errors.phone && (
              <div style={{ color: "red", marginTop: 5, fontSize: "14px" }}>{errors.phone}</div>
            )}
          </MDBox>
          <MDBox>
            <MDInput
              id="parent-email"
              type="email"
              label="Parent email"
              name="parentEmail"
              value={studentData.parentEmail}
              onChange={handleChange}
              onKeyPress={(event) => handleKeyPress(event, "phone_input")}
              style={{ width: "95%", marginBottom: "10px" }}
              error={!!errors.parentEmail}
              helperText={errors.parentEmail}
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <Typography component="p" style={{ fontSize: "16px" }}>
              Birth Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  defaultValue={dateValue}
                  views={["year", "month", "day"]}
                  onChange={(date) => setDateValue(date)}
                />
              </DemoContainer>
              {errors.dateOfBirth && (
                <div style={{ color: "red", marginTop: 5, fontSize: "14px" }}>
                  {errors.dateOfBirth}
                </div>
              )}
            </LocalizationProvider>
          </MDBox>
          <MDBox mb={2} mt={2}>
            <Typography component="p" style={{ fontSize: "16px" }}>
              Gender
            </Typography>
            <FormControl style={{ marginLeft: "0%" }}>
              <RadioGroup
                aria-label="Gender"
                row
                name="gender"
                value={studentData.gender}
                onChange={handleChange}
              >
                <FormControlLabel value="f" control={<Radio />} label="Female" />
                <FormControlLabel value="m" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
            {errors.gender && (
              <div style={{ color: "red", marginTop: 5, fontSize: "14px" }}>{errors.gender}</div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                marginBottom: "10%",
              }}
            >
              <MDButton
                variant="gradient"
                color="info"
                style={{ width: "50%" }}
                onClick={handleSubmitStudent}
              >
                Add Student
              </MDButton>
            </div>
          </MDBox>
        </MDBox>
        <FileUpload isStaff={isStaff} datas={data} />
      </MDBox>
    )
  );
};
AddingComp.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};
export default AddingComp;
