import { axiosInstanceAuth } from "config/axios-config";
import { notificationSetter } from "context/notification";
export const getNotification = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.get("/messageApproval");
    if (response) {
      notificationSetter(response.data.data);
    }
  } catch (error) {}
};

export const updateNotification = async (request_id, status) => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const localResponse = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const response = await axiosAuth.patch(`/messageApproval/${request_id}`, {
      approval_status: status,
      seen: true,
    });
    if (response) {
      notificationSetter(response.data.data);
      localResponse.message = response.data.message;
      localResponse.success = response.data.success;
      // return localResponse;
    } else {
      localResponse.message = "Error occured refresh the page and try again please.";
      // return localResponse;
    }
  } catch (error) {
    localResponse.message = error.data;
  }
  return localResponse;
};

export const getAllSchoolNotifications = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const localResponse = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const response = await axiosAuth.get("/notification");
    if (response) {
      localResponse.message = response.data.message;
      localResponse.success = response.data.success;
      localResponse.data = response.data.data;
    } else {
      localResponse.message = "Failed to get school notifications";
    }
  } catch (error) {
    localResponse.message = error.data.message;
  }
  return localResponse;
};
