export let profile = {
  staff_id: null,
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  subject_id: null,
  class_id: null,
  educational_level: "",
  role: "",
  school_name: "",
  school_id: null,
  accessToken: "",
  refreshToken: "",
};

export let dashboard = {
  totalstud: 0,
};

export let studentList = [];
