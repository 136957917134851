import axiosInstance from "config/axios-config.js";
import { axiosInstanceAuth } from "config/axios-config";
export const createTeacherClass = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.post("/teacher_class", data);
    if (res.status === 200) {
      response.success = true;
      response.message = res.data.message || "Teacher-Class association created successfully.";
      response.data = res.data.data;
      response.message = res.data.message || "Unexpected error occurred.";
    }
  } catch (error) {
    console.error("Error creating teacher-class association:", error);
    response.message = "Unable to create teacher-class association.";
  }

  return response;
};

export const getTeachersClasses = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;

  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const result = await axiosAuth.get("/teacher_class");
    response.success = true;
    response.message = "Data fetched successfully";
    response.data = result.data;
  } catch (error) {
    response.success = false;
    response.message = error.response ? error.response.data.message : "An error occurred";
  }

  return response;
};
export const getTeachersClassesByTeacherId = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const result = await axiosAuth.get(`/teacher_class/teacher`);
    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.success = false;
    response.message = error.response ? error.response.data.message : "An error occurred";
  }
  return response;
};

export const UpdateTeacherClass = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.put(`/teacher_class/${data.id}`, data);
    if (res.status === 200) {
      response.success = true;
      response.message = res.data.message || "Teacher-Class association updated successfully.";
      response.data = res.data.data;
    } else {
      response.message = "Unable to update teacher-class association.";
      console.error("Error updating teacher-class association:", error);
      response.success = false;
    }
  } catch (error) {
    console.error("Error updating teacher-class association:", error);
    response.message = "Unable to update teacher-class association.";
    response.success = false;
  }
  return response;
};

export const getTeacherClassByClassId = async (teacher_id) => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const result = await axiosAuth.get(`/teacher_class/teacher/${teacher_id}`);

    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.success = false;
    response.message =
      error.response?.data?.message || "An error occurred while fetching teacher class data.";
  }

  return response;
};
