import { snackbar } from "components/awesome_snackbar/snackbar";
import axiosInstance from "config/axios-config";
import { setToken } from "config/storageController";
import { profile } from "context/profile";
import { Role_Route } from "utils/role_route";

export const userLogin = async (userName, userPassword) => {
  try {
    const res = await axiosInstance.post("/account/login", {
      email: userName,
      password: userPassword,
    });

    // Check for success response and handle profile mapping
    if (res.data.success) {
      const roleRoutes = Role_Route(res.data.data.role);
      // Check if role has routes, if not show an error message and return false
      if (roleRoutes.length === 0) {
        snackbar("error", "You are not allowed to access the system");
        return false;
      }

      // Map profile data and set token if role is allowed
      profileDataMapper(res.data.data);
      setToken(res.data.data.accessToken); // Assuming setToken needs the access token
      snackbar("success", `${res.data.message}`);
      return true;
    } else {
      console.warn("Login failed:", res.data.message); // Log the reason for failure
      return false;
    }
  } catch (error) {
    console.error("Login error:", error.response ? error.response.data : error);
    return false;
  }
};

export const profileDataMapper = (data) => {
  profile.staff_id = data.staff_id;
  profile.subject_id = data.subject_id;
  profile.school_name = data.school_name;
  profile.school_id = data.school_id;
  profile.role = data.role;
  profile.refreshToken = data.refreshToken;
  profile.phone_number = data.phone_number;
  profile.last_name = data.last_name;
  profile.first_name = data.first_name;
  profile.email = data.email;
  profile.educational_level = data.educational_level;
  profile.accessToken = data.accessToken;
  profile.class_id = data.class_id;
};
