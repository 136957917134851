import { axiosInstanceAuth } from "config/axios-config";
export const getSetting = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;

  try {
    const response = await axiosAuth.get("/setting");
    // console.log("object returned", JSON.stringify(response, null, 2));
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "Failed to fetch settings.",
        data: null,
      };
    }
  } catch (error) {
    // console.error("Error fetching results:", error);
    return {
      success: false,
      message: error.message || "An error occurred while fetching settings.",
      data: null,
    };
  }
};
