import Card from "@mui/material/Card";
import { Avatar, Pagination } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ListComponent from "../StudentList";
import { FilterAlt } from "@mui/icons-material";
import List from "../List/list";
import { getStudents } from "utils/requests/students/studentsget";
import { getClassBySchool } from "utils/requests/class/getClass";

function BoadCastList(props) {
  const [click, setClick] = useState(false);
  const { handler, is_sending } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [fetched, setFetched] = useState([]);
  const [formData, setFormData] = useState({
    grade_level: "",
    grade_section: "",
  });
  const [isSpecificClass, setIsSpecificClass] = useState(false);
  const [clickItems, setClickItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [classData, setClassData] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const studentsPerPage = 4; // Set number of students per page to 5

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    setSelectedItems([]);
  }, [props.success]);

  const handleClick = (index, student) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
      handler(student.student_id, false); // Updated to use student_id
    } else {
      handler(student.student_id, true); // Updated to use student_id
      setSelectedItems([...selectedItems, index]);
    }
  };

  const handleGradeLevelChange = (e) => {
    const selectedGrade = Number(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      grade_level: selectedGrade,
      grade_section: "",
    }));

    // Update sections based on selected grade
    const sectionsForGrade = classData
      .filter((cls) => cls.grade_level === selectedGrade)
      .map((cls) => cls.section);
    setFilteredSections(sectionsForGrade);
  };

  const clickhandler = () => {
    setClick(!click);
    setIsSpecificClass(false);
  };

  const allSelected = () => {
    fetched.map((item, index) => {
      selectedItems.push(index);
    });
  };

  const menuSelected = (value) => {
    setClick(false); // Reset click on menu selection
  };

  useEffect(() => {
    const fetch = async () => {
      let students = await getStudents();
      setFetched(students.data);
    };
    fetch();
  }, []);

  const checkISelected = (index) => {
    handler(index);
    return !is_sending ? selectedItems.includes(index) : null;
  };

  const specificclickhandler = (booleane) => {
    setSpacific(booleane);
  };

  const specificClassUI = () => {
    setIsSpecificClass(true);
  };

  const handleSelectionChange = (event) => {
    const { id, value } = event.target;
    setClickItems((prevItems) => ({ ...prevItems, [id]: value }));
  };

  const handleClickSubmit = () => {
    //whensubmit the grade level and section  it filter students by grade level and section

    setFormData({
      grade_level: clickItems.grade_level,
      grade_section: clickItems.grade_section,
    });
    setClick(true);
    setIsSpecificClass(false);
  };

  const list_clear = () => {
    setSelectedItems([]);
  };

  // Pagination handlers
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the current students to display
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = fetched.slice(indexOfFirstStudent, indexOfLastStudent);

  return (
    <Card
      sx={{
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Choose receiver
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small" onClick={clickhandler}>
          view all &nbsp;
          <FilterAlt style={{ fontWeight: "bold" }} />
        </MDButton>
      </MDBox>
      <MDBox p={2} flex="1" display="flex" flexDirection="column" justifyContent="space-between">
        {!click && !isSpecificClass && (
          <>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              mt={0}
              sx={{ flexGrow: 1 }}
            >
              {currentStudents.length > 0 ? (
                currentStudents.map((student, index) => (
                  <button
                    onClick={() => handleClick(indexOfFirstStudent + index, student)}
                    key={index}
                    style={{
                      border: "none",
                      backgroundColor: selectedItems.includes(indexOfFirstStudent + index)
                        ? "#f0f2f5"
                        : "transparent",
                      outline: "none",
                      boxShadow: "none",
                      marginTop: 4,
                    }}
                  >
                    <ListComponent
                      name={student.first_name}
                      grade={`${student?.class?.grade_level} ${student?.class?.section}`}
                      selected={checkISelected(indexOfFirstStudent + index)}
                    />
                  </button>
                ))
              ) : (
                <p>there is no student list</p>
              )}
            </MDBox>
            {/* Pagination inside the card, positioned at the bottom */}
            <MDBox sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(fetched.length / studentsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </MDBox>
          </>
        )}
        {click && !isSpecificClass && (
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            <List
              updateSelectedItems={handler}
              uiUpdater={allSelected}
              menuSelected={menuSelected}
              handler={specificclickhandler}
              specificClassUI={specificClassUI}
              clear={list_clear}
            />
          </MDBox>
        )}
        {/* {!click && isSpecificClass && (
          <MDBox>
            <MDBox component="ul" display="flex" flexDirection="row" p={0} m={0}>
              <select
                id="grade_level"
                value={formData.grade_level}
                onChange={handleGradeLevelChange}
                style={{
                  width: "45%",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "grey",
                  border: "1px solid lightgrey",
                  marginTop: 10,
                }}
              >
                <option value="">Grade</option>
                {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}
                  </option>
                ))}
              </select>
              <select
                id="grade_section"
                value={formData.grade_section}
                onChange={(e) => setFormData({ ...formData, grade_section: e.target.value })}
                style={{
                  width: "45%",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  color: "grey",
                  border: "1px solid lightgrey",
                  marginTop: 20,
                  marginLeft: "5%",
                }}
                disabled={filteredSections.length === 0}
              >
                <option value="">Section *</option>
                {filteredSections.map((cls, index) => (
                  <option key={index} value={cls}>
                    {cls}
                  </option>
                ))}
              </select>
            </MDBox>
            <div className="mt-6 flex justify-center gap-x-2">
              <button
                onClick={handleClickSubmit}
                type="submit"
                className="flex-none rounded-md bg-blue-500 w-2/5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400"
              >
                Submit
              </button>
            </div>
          </MDBox>
        )} */}
      </MDBox>
    </Card>
  );
}

BoadCastList.propTypes = {
  handler: PropTypes.func.isRequired,
  is_sending: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

export default BoadCastList;
