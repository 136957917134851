import axios from "axios";
import { getToken } from "./storageController.js";
import { profile } from "context/profile.js";
import { isAuthenticated } from "context/isAuthenticated.js";
import { backend_url, local_url } from "./constants.js";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MODE === "development" ? local_url : backend_url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_MODE === "development" ? local_url : backend_url,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosInstanceAuth = () => {
  console.log(backend_url);
  if (isAuthenticated()) {
    let token = getToken();
    if (token === null || token === undefined) token = profile.accessToken;
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axiosUploadInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return { axiosInstance, axiosUploadInstance };
  } else {
    console.log("user Is not Authenticated");
  }
};

export default axiosInstance;
