import axiosInstance from "config/axios-config.js";

export const postresult = async (data) => {
  const response = {
    data: null,
    message: "",
    success: false,
    existData: null,
  };
  try {
    const res = await axiosInstance.post("/result", data);
    if (res.data.success) {
      response.message = res.data.message;
      response.success = res.data.success;
      response.data = res.data.data;
      response.existData = res.data.existData;
    }
  } catch (error) {
    console.log("error from server", error);
    response.message = error.response?.data?.message || "Failed to create result";
  }
  return response;
};
export const uploadResult = async (data) => {
  const response = {
    data: null,
    message: "",
    success: false,
  };
  try {
    response = await axiosInstance.post("/result/upload", data);
    if (response.success) {
      response.message = response.data.message;
      response.success = response.data.success;
      response.data = response.data.data;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "Failed to upload result";
  }
  return response;
};
