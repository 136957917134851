import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert"; // Import MDAlert component
import { resetPassword } from "utils/requests/user/resetPassword";
import PropTypes from "prop-types";

function PasswordConfirmation({ handleClose }) {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false); // State for showing old password
  const [showNewPassword, setShowNewPassword] = useState(false); // State for showing new password
  const [oldPassword, setOldPassword] = useState(""); // State for old password
  const [password, setPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [alertContent, setAlertContent] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  // Handle showing/hiding the old password
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  // Handle showing/hiding the new password
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Clear the alert when the input changes
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setShowAlert(false); // Clear any alert when the user is typing
  };

  // Password Validation Function
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    if (!hasUppercase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return "";
  };

  const handleSave = async () => {
    // Validate password before submission
    const validationError = validatePassword(password);
    if (validationError) {
      setAlertContent(validationError);
      setAlertColor("error");
      setShowAlert(true); // Show error alert
      return;
    }

    // Ensure passwords match
    if (password !== confirmPassword) {
      setAlertContent("Passwords do not match.");
      setAlertColor("error");
      setShowAlert(true); // Show error alert
      return;
    }

    // Reset password through API, including old password
    let res = await resetPassword(oldPassword, password);
    console.log("response: " + JSON.stringify(res, null, 2));
    if (res.success) {
      setAlertContent(res.message);
      setAlertColor("success");
      setShowAlert(true); // Show success alert
      // Automatically navigate to login after a successful password change
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setAlertContent(res.message || "Error changing password.");
      setAlertColor("error");
      setShowAlert(true); // Show error alert
    }
  };

  return (
    <MDBox>
      {/* Old Password Input */}
      <FormControl
        sx={{ width: "100%", marginBottom: "16px", marginTop: "8px" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-old-password">Old Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-old-password"
          type={showOldPassword ? "text" : "password"}
          value={oldPassword}
          onChange={handleInputChange(setOldPassword)}
          label="Old Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle old password visibility"
                onClick={handleClickShowOldPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* New Password Input */}
      <FormControl sx={{ width: "100%", marginBottom: "16px" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showNewPassword ? "text" : "password"}
          value={password}
          onChange={handleInputChange(setPassword)}
          label="New Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle new password visibility"
                onClick={handleClickShowNewPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* Confirm Password Input */}
      <FormControl sx={{ width: "100%", marginBottom: "16px" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirm-password"
          type="password"
          value={confirmPassword}
          onChange={handleInputChange(setConfirmPassword)}
          label="Confirm Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowNewPassword} // Use the same handler as new password for consistency
                onMouseDown={handleMouseDownPassword}
                edge="end"
              ></IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* Alert for errors or success */}
      {showAlert && (
        <MDAlert color={alertColor} dismissible sx={{ marginBottom: "16px" }}>
          {alertContent}
        </MDAlert>
      )}

      {/* Save button */}
      <MDButton id="signin" variant="gradient" color="info" fullWidth onClick={handleSave}>
        change password
      </MDButton>
    </MDBox>
  );
}

PasswordConfirmation.propTypes = {
  handleClose: PropTypes.func.isRequired, // Function to close the modal
};

export default PasswordConfirmation;
