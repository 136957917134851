import { setTeachers } from "layouts/tables/data/teachersData";
import { axiosInstanceAuth } from "config/axios-config";
export const getTeachers = async () => {
  const response = {
    success: false,
    data: null,
    message: "",
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.get("/staff");
    // console.log("res: " + JSON.stringify(res, null, 2));
    if (res.data.success) {
      response.status = res.data.success;
      response.data = res.data.data;
      response.message = res.data.message;
      setTeachers(res.data.data);
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const getOnlyTeachers = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const response = await axiosAuth.get("/staff/teacher").catch((error) => {
    // console.log(error);
  });

  setTeachers(response.data.data);
  return response;
};
