/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { Registration } from "components/Registration";
import StudentAdd from "../../OverLayForm";
import { profile } from "context/profile";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";
import AddingComp from "components/AddingComponent";
import { getStudents } from "utils/requests/students/studentsget";
import Button from "@mui/material/Button";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import imageUrl from "../../../assets/images/Gold Elegant Appreciation Certificate signature.png";
import StaffEditorModal from "../data/editpopup";
import StudentEditorModal from "../data/editstudent";
import { getTeachers } from "utils/requests/teachers/teacher";
import { LoadinSpiner } from "components/LoadingSpinner";

function Tables(props) {
  const routename = props.routename;
  const [isEditable, setIsEditable] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [fetchedStudents, setFetchedStudents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let fetchedData = routename === "students" ? await getTeachers() : await getStudents();
        console.log("fetched data: " + JSON.stringify(fetchedData, null, 2));
        setFetchedStudents(fetchedData.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [isRerender, routename]);

  useEffect(() => {
    if (isEditable) {
    }
  }, [isEditable]);
  const handleClose = () => {
    setIsEditable(false);
    setSelectedTeacher(null);
    setIsRerender((prev) => !prev);
  };

  const handleEdit = (teacher) => {
    setIsEditable(true);
    setSelectedTeacher(teacher);
  };
  const handleClick = () => {
    setIsBoxVisible((prev) => !prev);
    setIsRerender((prev) => !prev);
  };

  const handleDownload = (fileType) => {
    if (fetchedStudents.length === 0) {
      return;
    }

    if (fileType === "excel") {
      downloadExcel();
    } else if (fileType === "pdf") {
      downloadPDF();
    } else if (fileType === "certificate") {
      downloadCertificate();
    }

    setOpenDialog(false);
  };

  const downloadExcel = () => {
    console.log(fetchedStudents.length);
    const studentData = fetchedStudents.map((student) => ({
      "First Name": student.first_name,
      "Last Name": student.last_name,
      Grade: student?.class?.grade_level,
      Section: student?.class?.section,
      Average: student?.report?.average || 0,
      Rank: student?.report?.rank || 0,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(studentData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
    XLSX.writeFile(workbook, "students.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const studentsPerPage = 40;
    let yOffset = 20;

    doc.text("Student List", 10, 10);
    fetchedStudents.forEach((student, index) => {
      if (index > 0 && index % studentsPerPage === 0) {
        doc.addPage();
        yOffset = 20;
      }

      doc.text(
        `${index + 1}. ${student.first_name} ${student.last_name}, Grade: ${
          student?.class?.grade_level
        }, Section: ${student?.class?.section}, Average: ${student?.report?.average || 0}, Rank: ${
          student?.report?.rank || 0
        }`,
        10,
        yOffset
      );
      yOffset += 10;
    });

    doc.save("students.pdf");
  };

  const downloadCertificate = async () => {
    const img = new Image();

    const today = new Date();
    const dateStr = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    img.src = imageUrl;

    img.onload = function () {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [840, 600],
      });

      fetchedStudents.forEach((student, index) => {
        if (index > 0) {
          doc.addPage();
        }

        doc.addImage(img, "PNG", 0, 0, 840, 600);

        doc.setFontSize(24);
        doc.setTextColor(162, 133, 53);
        doc.text(`${student.first_name} ${student.last_name}`, 420, 280, { align: "center" });
        doc.text(`${profile.school_name}`, 428, 218, { align: "center" });

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(`${student?.class?.grade_level}`, 427, 335, { align: "center" });
        doc.text(`${student?.class?.section}`, 550, 335, { align: "center" });

        doc.text(`${student?.report?.average || 0}`, 350, 380, { align: "center" });
        doc.text(`${student?.report?.rank || "N/A"}`, 570, 380, { align: "center" });

        doc.text(`Date: ${dateStr}`, 700, 570, { align: "right" });
      });

      doc.save("students_certificates.pdf");
    };

    img.onerror = function () {
      console.error("Failed to load the image.");
    };
  };

  const { columns, rows } = authorsTableData({ handleEdit, routename, isRerender });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {isEditable ? (
                props.routename == "Teachers" ? (
                  <StaffEditorModal
                    // isEditable={isEditable}
                    onClose={handleClose}
                    teacher={selectedTeacher}
                    routeName={props.routename}
                  />
                ) : (
                  <StudentEditorModal
                    onClose={handleClose}
                    teacher={selectedTeacher}
                    routeName={props.routename}
                  />
                )
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                    {props.routename === "Teachers" ? "Staffs" : props.routename}
                  </MDTypography>

                  <MDBox display="flex" alignItems="center">
                    <IconButton style={{ padding: 0 }} onClick={handleClick}>
                      {isBoxVisible ? (
                        <Registration isVisible={true} />
                      ) : (
                        <Registration isVisible={false} />
                      )}
                    </IconButton>

                    {props.routename !== "Teachers" &&
                      (profile.role === "admin" || profile.role === "teacher") && (
                        <Button
                          variant="contained"
                          color="info"
                          startIcon={<Download />}
                          onClick={() => setOpenDialog(true)}
                          style={{ marginLeft: "10px", color: "blue" }}
                        >
                          Download
                        </Button>
                      )}
                  </MDBox>
                </MDBox>
              )}
              {isBoxVisible && !isEditable && (
                <MDBox pt={3}>
                  {loading ? (
                    <LoadinSpiner />
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              )}
              {!isBoxVisible && profile.role !== "teacher" && (
                <AddingComp isStaff={routename.toLowerCase() == "teachers"} pathname={routename} />
              )}

              {!isBoxVisible &&
                routename.toLowerCase() === "students" &&
                profile.role === "teacher" && <StudentAdd />}
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Select File Type to Download</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleDownload("pdf")} color="primary">
            Download PDF
          </Button>
          <Button onClick={() => handleDownload("excel")} color="primary">
            Download Excel
          </Button>
          <Button onClick={() => handleDownload("certificate")} color="primary">
            Download Certificate
          </Button>
        </DialogActions>
      </Dialog>

      {isBoxVisible && <Footer />}
    </DashboardLayout>
  );
}

Tables.propTypes = {
  routename: PropTypes.string.isRequired,
};

export default Tables;
