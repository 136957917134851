import { useEffect, useState } from "react";

import { Routes, Route, Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import SignIn from "layouts/authentication/sign-in";
import Card from "@mui/material/Card";
import { pink } from "@mui/material/colors";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import MDAlert from "components/MDAvatar";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OtpInput from "react-otp-input";
import { OTPInput, SlotProps } from "input-otp";
import bgImage from "assets/images/signIn-bg.jpg";
import LockIcon from "@mui/icons-material/Lock";
import PhoneInput from "react-phone-input-2";
import { verifyOtp } from "utils/requests/resetpassword/verifyotp";
import "react-phone-input-2/lib/style.css";
import { Alert } from "bootstrap";
import Countdown from "react-countdown";
import { forgot_password } from "../../../utils/requests/resetpassword/verifyotp";
import IconButton from "@mui/material/IconButton";
import PasswordConfirmation from "./newPassword";
import { LoadinSpiner } from "components/LoadingSpinner";
import Snackbar from "awesome-snackbar";
function Forgotten() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isotp, setIsotp] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState();
  const [phone, setPhone] = useState("");
  const [reset, setReset] = useState({});
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const confirmPasswordHandler = (event) => {
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {}, [isotp]);
  const handleChange = async (otp) => {
    setOtp(otp);
  };
  const handleContinue = async () => {
    if (phone === "" || phone === null) {
      setEmailError("phone is required.");
      return;
    } else {
      const response = await forgot_password(phone);
      if (response.success) {
        setIsotp(!isotp);
        setEmailError("");
        new Snackbar(response.message, {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "green"],
              ["color", "white"],
            ],
          },
        });
      } else {
        setEmailError(response.message);
      }
    }
  };
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    setEmailError("");
    setError("");
  };
  const handleVerify = async (phone, otp) => {
    if (otp) {
      // setReset({ phone_number: phone, otp: otp });
      const response = await verifyOtp(phone, otp);
      if (response.success) {
        setIsNewPassword(true);
        setIsotp(!isotp);
      } else {
        setEmailError(response.message);
      }
    } else {
      // console.log("Error occured on otp reset");
    }
  };
  const alertContent = (name) => (
    <MDTypography
      variant="body2"
      color="white"
      fontWeight="light"
      fontSize="default"
      textAlign="center"
    >
      {error}
    </MDTypography>
  );

  const handleOTPChange = (value) => {};

  const Completionist = () => <span>SMS sent</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <LockIcon fontSize="large" sx={{ color: pink[50] }} />
          {isotp ? (
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Reset password
            </MDTypography>
          ) : (
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Forgotten Password
            </MDTypography>
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={5}>
          {isotp && ( // Only render OTPInput if isopt is true
            <MDBox>
              <OtpInput
                placeholder="0000"
                inputStyle={{
                  color: "grey",
                  border: "1px gray solid",
                  borderRadius: "5px",
                  width: "50px",
                  height: "60px",
                }}
                containerStyle={{
                  columnGap: "5px",
                }}
                value={otp}
                onChange={handleChange}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(inputProps) => (
                  <input
                    // eslint-disable-next-line
                    {...inputProps}
                  />
                )}
              />
              {emailError && (
                <MDTypography variant="body2" color="error" fontWeight="light" fontSize="default">
                  {emailError}
                </MDTypography>
              )}
              <MDBox>
                <Countdown count={10} date={Date.now() + 10000} precision={3} />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={async () => {
                    await handleVerify(phone, otp);
                  }}
                >
                  Verify
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {!isotp && !isNewPassword && (
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <PhoneInput
                  id="phone_input"
                  country={"et"}
                  value={phone}
                  placeholder="+ (251) 000-111-222"
                  onChange={(numbers) => {
                    setPhone(numbers);
                  }}
                  preferredCountries={["et", "ke", "dj", "sd"]}
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputStyle={{
                    width: "100%",
                    height: "45px",
                  }}
                  autocompleteSearch={true}
                  onKeyPress={(event) => handleKeyPress(event, "parent-email")}
                />
                {emailError && (
                  <MDTypography variant="body2" color="error" fontWeight="light" fontSize="default">
                    {emailError}
                  </MDTypography>
                )}
              </MDBox>

              {sendingOtp ? (
                <LoadinSpiner />
              ) : (
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={async () => {
                      setSendingOtp(true);
                      await handleContinue();
                      setSendingOtp(false);
                    }}
                  >
                    Send
                  </MDButton>
                </MDBox>
              )}
              <MDBox mt={3} mb={1} textAlign="rightcenter" sx={{ display: "flex" }}>
                <MDTypography variant="button" color="text">
                  have an account
                </MDTypography>
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{ ml: 1, cursor: "pointer" }}
                >
                  Sign in
                </MDTypography>
                {error && (
                  <MDAlert color="primary" dismissible>
                    {alertContent("primary")}
                  </MDAlert>
                )}
              </MDBox>
            </MDBox>
          )}
          {isNewPassword && <PasswordConfirmation />}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Forgotten;
