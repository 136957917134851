import axiosInstance from "config/axios-config.js";

export const createExam = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.post("/exam", data);
    console.log("response", JSON.stringify(res, null, 2));
    if (res.success) {
      response.success = true;
      response.message = res.data.message || "Exam created successfully.";
      response.data = res.data.data;
    } else {
      response.message = res.data.message || "Failed to create exam.";
      response.success = false;
      response.data = null;
    }
    return response;
  } catch (e) {
    response.message = e.response.data.message;
    response.success = false;
    response.data = null;
    return response;
  }
};

export const updateExam = async (exam_id, data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.put(`/exam/${exam_id}`, data);

    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message || "Exam updated successfully.";
      response.data = res.data.data;
    } else {
      response.message = res.data.message || "Failed to update exam.";
      response.success = false;
      response.data = null;
    }
  } catch (error) {
    response.message = error.response.data.message || "An error occurred while updating the exam.";
    response.success = false;
    response.data = null;
  }

  return response;
};
export const deleteExam = async (exam_id) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/exam/${exam_id}`);
    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message || "Exam deleted successfully.";
      response.data = null;
    } else {
      response.message = res.data.message || "Failed to delete exam.";
      response.success = false;
      response.data = null;
    }
  } catch (error) {
    response.message = error.response.data.message || "An error occurred while deleting the exam.";
    response.success = false;
    response.data = null;
  }
  return response;
};
