/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Avatar } from "@mui/material";
import AvatarColor from "components/ColorAvater/ColorAvater";
import { getTeachers } from "utils/requests/teachers/teacher";
import { getStudents } from "utils/requests/students/studentsget";
import { profile } from "context/profile";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";

// Define the Author component
const Author = ({ name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <Avatar sx={{ bgcolor: AvatarColor(name) }}>{name.charAt(0)}</Avatar>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

// Main Data Component
export default function Data(props) {
  const { routename, handleEdit, isRerender } = props;
  const [fetched, setFetched] = useState([]);

  const firstLetterCapitalizer = (name) => {
    return typeof name === "string" && name.length > 0
      ? name.charAt(0).toUpperCase() + name.slice(1)
      : "";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fetchedData =
          routename.toLowerCase() === "teachers" ? await getTeachers() : await getStudents();

        setFetched(fetchedData.data);
        // // console.log("fetched data", fetchedData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [routename, isRerender]);

  // Generate the correct header based on the route and role
  const teacherHeader = headerIdentifier(routename, profile.role);
  return {
    columns: [
      { Header: teacherHeader[0], accessor: "teacher", width: "45%", align: "left" },
      { Header: teacherHeader[1], accessor: "phone_No", align: "left" },
      { Header: teacherHeader[2], accessor: "subject", align: "center" },
      { Header: teacherHeader[3], accessor: "represented_class", align: "center" },
      { Header: teacherHeader[4], accessor: "teaching_class", align: "center" },
      { Header: teacherHeader[5], accessor: "header5", align: "center" },
      { Header: teacherHeader[6], accessor: "edit", align: "center" },
    ],
    rows: fetched.map((item) => ({
      teacher: (
        <Author
          name={`${firstLetterCapitalizer(item.first_name)} ${
            routename === "students" ? item.middel_name : item.last_name
          }`}
          email={item.email}
        />
      ),
      phone_No: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {routename.toLowerCase() === "students" ? item?.class?.grade_level : item.phone_number}
        </MDTypography>
      ),
      subject: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {routename.toLowerCase() === "students"
            ? firstLetterCapitalizer(item?.class?.section)
            : item.subject_id}
        </MDTypography>
      ),
      represented_class: (
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {routename.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar")
            ? `${item?.parent?.first_name} ${item?.parent?.last_name}` || "Undefined"
            : profile.role.toLowerCase() === "teacher" || profile.role.toLowerCase() === "encoder"
            ? item?.report?.totalMarks
            : item.represent_grade === null
            ? "Not Assigned"
            : item?.class?.grade_level}
        </MDTypography>
      ),
      teaching_class: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {routename.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar")
            ? item?.parent?.phone_number || "Undefined"
            : profile.role.toLowerCase() === "teacher" || profile.role.toLowerCase() === "encoder"
            ? item?.report?.rank
            : item.represent_section === null
            ? "Not Assigned"
            : item?.class?.section}
        </MDTypography>
      ),
      header5: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {routename.toLowerCase() === "students" &&
          (profile.role.toLowerCase() === "admin" || profile.role.toLowerCase() === "registrar") ? (
            <span style={{ color: "#E6E6E6" }}>not yet</span>
          ) : profile.role.toLowerCase() === "teacher" ||
            profile.role.toLowerCase() === "encoder" ? (
            item?.report?.attendance
          ) : item.email === null ? (
            "don't have email"
          ) : (
            item.role
          )}
        </MDTypography>
      ),
      ...(profile.role !== "teacher"
        ? {
            edit: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                <EditIcon fontSize="small" onClick={() => handleEdit(item)} />
              </MDTypography>
            ),
          }
        : {}),
    })),
  };
}

// Function to determine headers based on role and route
export const headerIdentifier = (routename, role) => {
  const TeacherHeader = ["Teacher", "Tel no.", "Subject", "Rep.Grade", "Section", "Role", ""];
  const StudentToRegistrar = [
    "Student",
    "Grade",
    "Section",
    "Parent name",
    "parent Tel. no",
    "Payment",
    "",
  ];
  const StudentToGardian = ["Student", "Grade", "Section", "Average", "Rank", "Attendance", ""];
  // const ScheduleHeader = ["#", "Time", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  if (routename.toLowerCase() === "teachers") return TeacherHeader;
  else if (
    routename.toLowerCase() === "students" &&
    (role.toLowerCase() === "admin" || role.toLowerCase() === "registrar")
  )
    return StudentToRegistrar;
  else if (routename.toLowerCase() === "students" && role.toLowerCase() === "teacher")
    return StudentToGardian;
};

// Define PropTypes
Data.propTypes = {
  routename: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isRerender: PropTypes.bool.isRequired,
};
