import { profile } from "../context/profile.js";

export const setToken = () => {
  try {
    const token = profile.accessToken;
    const refreshToken = profile.refreshToken;
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    getToken();
    return token;
  } catch (error) {
    // console.log("Error: on set token");
  }
};

export const getToken = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken === null) profile.accessToken;
  return storedToken;
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const removeToken = () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  } catch (error) {
    // console.log("Error: on token Remove!");
  }
};
export const setResetToken = (token) => {
  try {
    localStorage.setItem("reset-token", token);
  } catch (error) {
    // console.log(error);
  }
};

export const getResetToken = () => {
  const storedToken = localStorage.getItem("reset-token");
  if (storedToken === null) return storedToken;
  return null;
};
