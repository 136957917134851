import axiosInstance from "config/axios-config";
import { axiosInstanceAuth } from "config/axios-config";

export const createRequest = async (data) => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const response = {
    message: "",
    success: false,
    data: null,
  };
  try {
    const res = await axiosAuth.post(`/notification`, data);
    if (res.data.success) {
      response.data = res.data.data;
      response.message = res.data.message;
      response.success = res.data.success;
    }
    return response;
  } catch (error) {
    response.message = error.response.data.message;
    return response;
  }
};

export const getRequestBySender = async () => {
  let response = {
    message: "",
    success: "",
    data: null,
  };
  try {
    const result = await axiosInstance.get(`/notification/sender`);
    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.success = false;
    response.message = "Failed to fetch request";
    // console.error("Error fetching request:", error);
  }
  return response;
};

export const getAllRequest = async () => {
  const response = {
    message: "",
    success: false,
    data: null,
  };
  try {
    const result = await axiosInstance.get(`/notification`);
    // console.log("result from get all requests ", JSON.stringify(result, null, 2));
    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.success = false;
    response.message = "Failed to fetch requests";
    // console.error("Error fetching requests:", error);
  }
  return response;
};

export const updateRequestStatus = async (id, data) => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const response = await axiosAuth.put(`/notification/${id}`, data);
    if (response.success) {
      return {
        success: true,
        message: response.message,
        data: response.data,
      };
    }
  } catch (error) {
    // console.log(error.data);
  }
};
