import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from "@mui/material";
import Snackbar from "awesome-snackbar";
import StudentField from "../../components/StudentGrade";
import MDBox from "components/MDBox";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { getStudents } from "utils/requests/students/studentsget";
import MDButton from "components/MDButton";
import { profile } from "context/profile";
import { postresult } from "utils/requests/result/resultpost";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { getExamType } from "utils/requests/exam/examrequest";
import { getTeachersClassesByTeacherId } from "utils/requests/teachers/assignClass";
import ResultUpload from "layouts/examResult/examUpload";
import CryptoJS from "crypto-js";
import ExistResult from "./existResult";
import ExistData from "layouts/examResult/existData";

export default function StudentAdd() {
  const [examType, setExamType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [studentslist, setStudentsList] = useState([]);
  const [examTypeList, setExamTypeList] = useState([]);
  const [marks, setMarks] = useState({});
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [teacherClass, setTeacherClass] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [maxResult, setMaxResult] = useState(100);
  const [school_plan, setSchool_plan] = useState(null);
  const studentsPerPage = 5;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [studentsWithZeroMarks, setStudentsWithZeroMarks] = useState([]);
  const [existData, setExistData] = useState([]);
  const [closeExistingPage, setCloseExistingPage] = useState(true);
  const [receivedData, setReceivedData] = useState([]);

  const totalPages = isFiltered
    ? Math.ceil(filteredStudent.length / studentsPerPage)
    : Math.ceil(studentslist.length / studentsPerPage);
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = isFiltered
    ? filteredStudent.slice(indexOfFirstStudent, indexOfLastStudent)
    : studentslist.slice(indexOfFirstStudent, indexOfLastStudent);

  // Fetch exam types
  useEffect(() => {
    const examTypes = async () => {
      try {
        const response = await getExamType();
        setExamTypeList(response.data);
      } catch (error) {
        console.error("Error fetching exam types:", error);
        setExamTypeList([]);
      }
    };
    examTypes();
  }, []);

  // Fetch students
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const students = await getStudents();
        setStudentsList(students.data || []);
        console.log("school plan", JSON.stringify(students, null, 2));
        setSchool_plan(students.school_plan);
      } catch (error) {
        console.error("Error fetching students:", error);
        setStudentsList([]);
      }
    };
    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchTeacherClasses = async () => {
      try {
        const response = await getTeachersClassesByTeacherId();
        // // console.log("response: " + JSON.stringify(response.data, null, 2));
        setTeacherClass(response.data);
      } catch (error) {
        console.error("Error fetching teacher classes:", error);
        setTeacherClass([]);
      }
    };
    fetchTeacherClasses();
  }, []);

  useEffect(() => {
    const filterStudents = () => {
      if (selectedClass) {
        const filtered = studentslist.filter((student) => student.class_id === selectedClass);
        setFilteredStudent(filtered);
        setIsFiltered(true);
      } else {
        setFilteredStudent([]);
        setIsFiltered(false);
      }
    };
    filterStudents();
  }, [selectedClass, studentslist]);

  useEffect(() => {
    const selectedExam = examTypeList.find((exam) => exam.exam_id === examType);
    setMaxResult(selectedExam ? selectedExam.max_result : 100);
  }, [examType, examTypeList]);

  // // console.log("teacher classes", JSON.stringify(teacherClass, null, 2));

  const handleExamTypeChange = (event) => {
    setExamType(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleMarkChange = (index, value) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [index]: value,
    }));
  };
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const calculateRank = (studentsWithMarks) => {
    const sortedStudents = [...studentsWithMarks].sort((a, b) => b.mark - a.mark);
    let rank = 1;
    return sortedStudents.map((student, index) => {
      if (index > 0 && student.mark === sortedStudents[index - 1].mark) {
        student.mark_rank = rank;
      } else {
        student.mark_rank = rank;
        rank = student.mark_rank + 1;
      }
      return student;
    });
  };

  const intializeSubmit = () => {
    if (!school_plan) {
      new Snackbar(
        `A school plan is required. Please notify the school admin to create a school plan.`,
        {
          theme: "light",
          position: "top-center",
          style: {
            container: [
              ["background-color", "#ff5e28"],
              ["color", "white"],
            ],
          },
        }
      );
      return;
    }

    if (!examType) {
      snackbar("error", "Please select an exam type before submitting marks.");
      return false;
    }

    const zeroMarkStudents = [];

    // Loop over the studentslist
    for (const student of studentslist) {
      const studentId = student.student_id;
      let mark = marks[studentId];
      if (mark === undefined || mark === null) {
        mark = 0;
      }
      const markNumber = Number(mark);

      if (isNaN(markNumber) || markNumber < 0 || markNumber > maxResult) {
        snackbar(
          "error",
          `Mark for student ${student.first_name} ${student.middle_name} ${student.last_name} must be a number between 0 and ${maxResult}.`
        );
        return false;
      }

      if (markNumber === 0) {
        zeroMarkStudents.push(student);
      }
    }
    setStudentsWithZeroMarks(zeroMarkStudents);
    if (zeroMarkStudents.length > 0) {
      setOpenConfirmation(true);
      return;
    }
    handleSubmit();
  };
  const handleSubmit = async () => {
    const hasNonZeroMark = Object.values(marks).some((mark) => mark > 0);
    if (!hasNonZeroMark) {
      snackbar("error", "At least one student must have a mark greater than 0.");
      return;
    }

    const studentsWithMarks = studentslist.map((student) => ({
      student_id: student.student_id,
      mark: marks[student.student_id] !== undefined ? Number(marks[student.student_id]) : 0,
    }));

    const rankedStudents = calculateRank(studentsWithMarks);

    const dataToSend = {
      subjectName: profile.subject_id,
      exam_id: examType,
      students: rankedStudents.map((student) => ({
        exam_id: examType,
        student_id: student.student_id,
        mark: student.mark,
        mark_rank: student.mark_rank,
        comment: student.mark >= maxResult * 0.7 ? "Great job!" : "Needs improvement",
      })),
    };

    // console.log("data for sending to server", JSON.stringify(dataToSend, null, 2));

    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!secretKey) {
      console.error("Secret key is not defined.");
      return;
    }

    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToSend), secretKey).toString();

    try {
      const response = await postresult({ data: encryptedData });
      console.log("data for respone", JSON.stringify(response, null, 2));

      if (response.success) {
        snackbar("success", response.message);
        setOpenConfirmation(false);
        setExistData(response.existData);
      } else {
        snackbar("error", response.message);
      }
      setMarks({});
    } catch (error) {
      snackbar("error", "Failed to submit results.");
    }
  };

  const handleReceivedData = (data) => {
    setReceivedData(data);
  };

  return (
    <>
      {existData && existData.length > 0 && closeExistingPage ? (
        <ExistResult
          existResult={existData}
          close={() => setCloseExistingPage(!closeExistingPage)}
        />
      ) : receivedData && receivedData.length && closeExistingPage ? (
        <ExistData
          existResult={receivedData}
          close={() => setCloseExistingPage(!closeExistingPage)}
        />
      ) : (
        <MDBox pl={10} pt={5} bgcolor="lightgreen" borderRadius="16px" boxShadow={3}>
          <Dialog open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
            <DialogTitle>Confirm Submission</DialogTitle>
            <DialogContent
              style={{
                maxWidth: "600px",
                overflowY: "auto",
              }}
            >
              <Typography>
                The following students have a mark of 0. Are you sure you want to continue?
              </Typography>
              <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                {studentsWithZeroMarks.map((student, index) => (
                  <li key={student.student_id}>
                    {student.first_name} {student.middle_name} {student.last_name}
                    {index < studentsWithZeroMarks.length - 1 && (
                      <Divider style={{ margin: "10px 0" }} />
                    )}
                  </li>
                ))}
              </ul>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => setOpenConfirmation(false)}
                color="error"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </MDButton>
              <MDButton onClick={handleSubmit} color="success">
                Confirm
              </MDButton>
            </DialogActions>
          </Dialog>
          <Box display="flex">
            <Box flex="1" mr={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={{ xs: "column", md: "row" }}
                  >
                    <FormControl style={{ width: "45%" }} variant="outlined" margin="normal">
                      <InputLabel id="exam-type-label">Exam Type</InputLabel>
                      <Select
                        labelId="exam-type-label"
                        id="exam-type"
                        value={examType}
                        onChange={handleExamTypeChange}
                        label="Exam Type"
                        sx={{ height: "40px", display: "flex", alignItems: "center" }}
                      >
                        {examTypeList.length > 0 ? (
                          examTypeList.map((exam) => (
                            <MenuItem key={exam.exam_id} value={exam.exam_id}>
                              {exam.exam_name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">No exam types available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl style={{ width: "45%" }} variant="outlined" margin="normal">
                      <InputLabel id="class-label">Class</InputLabel>
                      <Select
                        labelId="class-label"
                        value={selectedClass}
                        onChange={handleClassChange}
                        label="Class"
                        sx={{ height: "40px", display: "flex", alignItems: "center" }}
                      >
                        {teacherClass.map((cls) => (
                          <MenuItem key={cls?.class?.class_id} value={cls?.class?.class_id}>
                            {cls?.class?.grade_level} {cls?.class?.section}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>

                  <Typography variant="h6" gutterBottom>
                    Students
                  </Typography>

                  {currentStudents.map((student) => (
                    <StudentField
                      key={student.student_id}
                      student={student}
                      index={student.student_id}
                      sizes={currentStudents.length}
                      handleMarkChange={handleMarkChange}
                      selectedStudents={selectedStudents}
                      // handleSelect={handleSelect}
                      marks={marks}
                    />
                  ))}

                  <MDButton onClick={intializeSubmit} variant="gradient" color="info" fullWidth>
                    Submit
                  </MDButton>
                </Grid>

                <Grid item xs={6}>
                  <ResultUpload onDataReceived={handleReceivedData} />
                </Grid>
              </Grid>

              <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  Page {currentPage} of {totalPages}
                </Typography>
                <div>
                  <MDButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    <ChevronLeft />
                  </MDButton>
                  <MDButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <ChevronRight />
                  </MDButton>
                </div>
              </Box>
            </Box>
          </Box>
        </MDBox>
      )}
    </>
  );
}
